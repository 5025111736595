import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

import Context from '#context'
import checkToast from '#toast'

import { installationPlace } from '#helper/showData.js'
import { defaultHeader } from '#helper/Fetch API/request'

import LoadingScreen from '#comp/LoadingScreen.jsx'

import EditDevices from './EditDevices'

// FUTURE: v3.0 - Unite with EditDevices and Success Components.

/**
 * @class
 * @classdesc - Shows the details page and handles reloads.
 * @example
 * <Wrap routeEl={Details} />
 */
export default class Details extends Component {
	static contextType = Context

	state = {
		loading: true,
		device: null,
		falseTenant: false,
		editInputs: false,
	}

	/**
	 * @typedef {Object} ParamsShape
	 * @property {string} tenantId - The tenantId parameter of the url.
	 * @property {...*} [otherProps] - Additional properties that may be present in the params.
	 * @typedef {Object} PropTypes
	 * @property {ParamsShape} params
	 * @property {Object} [locations]
	 * @property {ReactNode} routeEl
	 */
	static propTypes = {
		params: PropTypes.shape({
			tenantId: PropTypes.string.isRequired,
		}),
		locations: PropTypes.object,
		routeEl: PropTypes.func.isRequired,
	}
	static defaultProps = {
		params: { tenantId: '' },
	}

	AccessToken = this.context.auth.access_token

	/**
	 * Changes the editable input fields and updates the state.
	 *
	 * @param {Object} editInputs - The new editable input fields.
	 * @returns {void}
	 */
	changeEditInputs = (editInputs) => {
		this.setState({ editInputs })
	}

	/**
	 * Sends a downlink command to the device to change its state.
	 *
	 * @async
	 * @param {Boolean} reset - Specifies whether to reset (true) or set (false) the state.
	 * @returns {void}
	 */
	clickDownlink = async (reset) => {
		const { apiFetch, instance } = this.context
		const { device } = this.state

		const myBody = {
			deviceid: device.id,
			type: 'out1_' + (reset ? 'reset' : 'set'),
			parameters: [{ name: 'data', value: '' }],
		}
		const downlinkRequest = await apiFetch(
			`${instance.api}/Commands?tenantId=${device.tenantId}`,
			defaultHeader(this.AccessToken, 'PUT', myBody)
		)
		if (downlinkRequest.logout) return

		// FUTURE: v2.2 - Success & Error handling with commands Downlink (toast?)
		if (downlinkRequest.ok) {
			// Successful
		} else {
			// Error
		}
	}

	/**
	 * Loads device data from the server and updates the state.
	 *
	 * @async
	 * @returns {Object|null} - The loaded device data or null in case of an error.
	 */
	parentLoadDevice = async () => {
		const { apiFetch, instance, setBreadcrumb, t } = this.context
		let device = null

		const deviceRequest = await apiFetch(
			`${instance.api}/Device/${this.props.params.deviceId}`,
			this.AccessToken
		)
		if (deviceRequest.logout) return device

		if (deviceRequest.ok) {
			device = deviceRequest.data
			setBreadcrumb('device', installationPlace(device.attributes))
		} else {
			checkToast(t, 13002)
		}

		this.setState({ device })
		return device
	}

	componentDidMount = async () => {
		const oneDevice = await this.parentLoadDevice()

		if (Number(this.context.tenant.id) !== oneDevice?.tenantId) {
			checkToast(this.context.t, 13003)
			this.setState({ falseTenant: true })
		}

		this.setState({ loading: false })
	}

	componentWillUnmount = () => {
		this.context.setBreadcrumb('device', null)
	}

	render() {
		const { t } = this.context
		const { loading, falseTenant, editInputs, device } = this.state
		const { params } = this.props

		if (loading) {
			return <LoadingScreen.Spinner className="mt-4" />
		}

		if (falseTenant) {
			return <Navigate to=".." replace />
		}

		return (
			<EditDevices
				changeEditInputs={this.changeEditInputs}
				clickDownlink={this.clickDownlink}
				device={device}
				editInputs={editInputs}
				parentLoadDevice={this.parentLoadDevice}
				navigatePhoto={'.'}
				newTenantId={params.tenantId}
				title={t('bread.details')}
			/>
		)
	}
}
