import React, { Component } from 'react'
import { faPlus, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OutlinedBox from '#comp/Custom/OutlinedBox.jsx'
import Input from '#comp/Custom/Input'
import Toggle from '#comp/Custom/Toggle'
import Context from '#context'
import checkToast from '#toast'
import { focusColor } from '#helper/colors'

// DOKU:

export default class Notification extends Component {
	static contextType = Context

	initMails = {
		alarm_active: this.props.device.attributes.alarm_active === '1',
		alarm_emails:
			this.props.device.attributes.alarm_emails?.split(';') || [],
		currentEmail: '',
	}

	state = this.initMails

	isValidEmail = (email) => {
		const emailRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return emailRegex.test(email)
	}

	addMail = () => {
		const { currentEmail } = this.state

		if (this.isValidEmail(currentEmail)) {
			this.setState((prevState) => ({
				alarm_emails: [...prevState.alarm_emails, currentEmail],
				currentEmail: '',
			}))
		} else {
			checkToast(this.context.t, 11009)
		}
	}

	deleteMail = (email) => {
		this.setState((prevState) => ({
			alarm_emails: prevState.alarm_emails.filter(
				(mail) => mail !== email
			),
		}))
	}

	handleToggleChange = () => {
		this.setState((prevState) => ({
			alarm_active: !prevState.alarm_active,
		}))
	}

	handleInputChange = (e) => {
		this.setState({ currentEmail: e.target.value })
	}

	isInputColor = (mail) => {
		// TODO: When Empty, dann weder grün noch rot
		if (mail === '') return false
		return !Boolean(mail && this.isValidEmail(mail))
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState !== this.state) {
			this.props.changeParent(this.state)
		}
		if (prevProps.editInputs !== this.props.editInputs) {
			this.setState({ ...this.initMails })
			this.props.changeParent(this.initMails)
		}
	}

	render() {
		const { t } = this.context
		const { alarm_active, alarm_emails, currentEmail } = this.state
		const { editInputs } = this.props

		return (
			<OutlinedBox
				className="w-full mb-4 mt-2"
				label={t('devices.notification.label')}
				error={!alarm_active}
				success={alarm_active}
			>
				{editInputs ? (
					<>
						<div>
							<Toggle
								onChange={this.handleToggleChange}
								isChecked={alarm_active}
							>
								{t('devices.notification.toggle')}:
							</Toggle>
							<div className="mt-1">
								<Input
									success={!this.isInputColor(currentEmail)}
									error={this.isInputColor(currentEmail)}
									icon={
										<button onClick={this.addMail}>
											<FontAwesomeIcon
												icon={faPlus}
												size="xl"
											/>
										</button>
									}
									value={currentEmail}
									name="Emails"
									onChange={this.handleInputChange}
								>
									{t('devices.notification.addMail')}
								</Input>
							</div>
						</div>
						<div className="mt-2 flex gap-2 flex-wrap">
							{alarm_emails.map((email) => (
								<div
									key={email + '_email'}
									className={`rounded-md border flex space-x-2 p-1 ${
										focusColor().border
									}`}
								>
									<div className="truncate max-w-[240px]">
										{email}
									</div>
									{editInputs && (
										<button
											onClick={() =>
												this.deleteMail(email)
											}
										>
											<FontAwesomeIcon
												icon={faTimesCircle}
											/>
										</button>
									)}
								</div>
							))}
						</div>
					</>
				) : (
					<div>
						<div
							className={`font-bold italic ${
								focusColor(!alarm_active, alarm_active).text
							}`}
						>
							{t(
								`devices.notification.${
									alarm_active
										? 'activeNotification'
										: 'inactiveNotification'
								}`
							)}
						</div>
						<p>
							{t('devices.notification.allMails') +
								' ' +
								alarm_emails.join(', ')}
						</p>
					</div>
				)}
			</OutlinedBox>
		)
	}
}
