import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Context from '#context'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons'

import { Button } from 'flowbite-react'

/**
 * @class
 * @classdesc - Send Downlinks to API.
 * @example
 * <Downlinks
 * clickDownlink={clickDownlink}
 * reloadPage={this.reloadPage}
 * />
 */
export default class Downlinks extends Component {
	static contextType = Context

	/**
	 * @typedef {Object} PropTypes
	 * @property {Function} clickDownlink - Send downlink to API.
	 * @property {Function} reloadPage - Reload parent page.
	 */
	static propTypes = {
		clickDownlink: PropTypes.func.isRequired,
		reloadPage: PropTypes.func.isRequired,
	}
	static defaultProps = {
		clickDownlink: () => {},
		reloadPage: () => {},
	}

	render() {
		const { t } = this.context
		const { clickDownlink, reloadPage } = this.props

		return (
			<div>
				<div className="mb-1">{t('devices.output')}:</div>
				<div className="flex space-x-2 mb-4">
					<Button
						className="w-1/2"
						color="success"
						onClick={() => clickDownlink(false)}
					>
						{t('all.on')}
					</Button>
					<Button
						className="w-1/2"
						color="failure"
						onClick={() => clickDownlink(true)}
					>
						{t('all.off')}
					</Button>
					<Button onClick={reloadPage.bind(this)}>
						<FontAwesomeIcon size="xl" icon={faRotateRight} />
					</Button>
				</div>
			</div>
		)
	}
}
