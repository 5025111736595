import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import Context from '#context'
import checkToast from '#toast'

import { saveLS } from '#helper/localStorage'

import LoadingScreen from '#comp/LoadingScreen'
import Wrap from '#comp/Wrapper/Wrap'

import Router from './allDevices/addDevice/Router'
import Details from './Details/Details'
import Devices from './allDevices/Devices'
import Camera from './allDevices/Camera'

/**
 * @class
 * @classdesc The Router for displaying devices for a tenant.
 * @example
 * <Wrap routeEl={DeviceRouter} />
 */
export default class DeviceRouter extends Component {
	static contextType = Context

	state = {
		loading: true,
		tenant: null,
		catalogue: [],
	}

	/**
	 * @typedef {Object} ParamsShape
	 * @property {string} tenantId - The tenantId parameter of the url.
	 * @property {...*} [otherProps] - Additional properties that may be present in the params.
	 * @typedef {Object} PropTypes
	 * @property {ParamsShape} params
	 * @property {Object} [locations]
	 * @property {ReactNode} routeEl
	 */
	static propTypes = {
		params: PropTypes.shape({
			tenantId: PropTypes.string.isRequired,
		}),
		locations: PropTypes.object,
		routeEl: PropTypes.func.isRequired,
	}
	static defaultProps = {
		params: { tenantId: '' },
	}

	/**
	 * Adds a new tenant to the list of recent tenants, stored in local storage. Limits the list to a maximum of 5 tenants.
	 *
	 * @param {Number} id - The id of the tenant.
	 * @param {String} name - The name of the tenant.
	 * @returns {void}
	 */
	recentToLS = (id, name) => {
		var arr = this.context.recentTenants
		var newObj = { id, name }

		var index = arr.findIndex((item) => item.id === id)
		if (index !== -1) {
			arr.splice(index, 1)
			arr.unshift(newObj)
		} else if (arr === null || arr.length === 0) {
			arr = [newObj]
		} else if (arr.length < 5) {
			arr.unshift(newObj)
		} else {
			arr.pop()
			arr.unshift(newObj)
		}

		saveLS('recent_' + this.context.instance.shortLink, arr)
	}

	fetchCatalogue = async () => {
		const { apiFetch, instance, auth, t } = this.context
		const catalogueRequest = await apiFetch(
			instance.api +
				'/Catalogue/data?tenantId=' +
				this.props.params.tenantId,
			auth.access_token
		)
		if (catalogueRequest.logout) return null

		if (!catalogueRequest.ok) {
			checkToast(t, 12007)
			return null
		}
		return catalogueRequest.data
	}

	componentDidMount = async () => {
		var id = this.props.params.tenantId

		const tenant = await this.context.fetchOneTenant(id)
		const catalogue = await this.fetchCatalogue(id)
		if (tenant === null || catalogue === null) return

		if (tenant) {
			this.recentToLS(id, tenant.name)
			this.context.setBreadcrumb('tenant', tenant.name)
		}
		this.setState({ catalogue, tenant, loading: false })
	}

	render() {
		const { isRole } = this.context
		const { tenant, loading, catalogue } = this.state

		if (loading) {
			return <LoadingScreen.Spinner className="mt-4" />
		}

		// FUTURE: v2.3 - Split Router for "deviceid"

		return (
			<Context.Provider
				value={{
					...this.context,
					catalogue,
					tenant,
				}}
			>
				<Routes>
					<Route path="/" element={<Wrap routeEl={Devices} />} />
					<Route
						path="/device/:deviceId"
						element={<Wrap routeEl={Details} />}
					/>
					<Route
						path="/device/:deviceId/camera"
						element={<Wrap routeEl={Camera} />}
					/>
					{/* <Route
						path="/usecase/*"
						element={<Wrap routeEl={DeviceListRouter} />}
					/> */}
					{isRole('edit') && (
						<Route path="/addDevice/*" element={<Router />} />
					)}
					<Route path="*" element={<Navigate to="./" replace />} />
				</Routes>
			</Context.Provider>
		)
	}
}
