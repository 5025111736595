import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Context from '#context'

import { appStatus, lastTimestamp } from '#helper/showData.js'

/**
 * @class
 * @classdesc - Displaying the status information of a device.
 * @example
 * <StatusRow device={device} />
 */
export default class StatusRow extends Component {
	static contextType = Context

	/**
	 * @typedef {Object} PropTypes
	 * @property {Object} [device] - The device object.
	 */
	static propTypes = {
		device: PropTypes.object,
	}
	static defaultProps = {
		device: {
			attributes: {},
			type: '',
		},
	}

	render() {
		const { t } = this.context
		const { device } = this.props

		return (
			<div className="flex w-full justify-between items-center xxs:flex-row flex-col space-y-2 xxs:space-y-0 mt-2">
				<div className="border border-gray-800 dark:border-gray-500 w-32 h-10 rounded-md flex items-center justify-center">
					{appStatus(device.attributes.app_status, t)}
				</div>
				<div className="xxs:text-right text-center truncate text-sm">
					<div className="first-letter:uppercase">
						{device.type.split('_')[1]}
					</div>
					<div>{lastTimestamp(device.attributes.last_timestamp)}</div>
				</div>
			</div>
		)
	}
}
