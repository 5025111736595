import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import Context from '#context'

import SingleButton from '#comp/Custom/SingleBottomButton'

import {
	faPlus,
	faRotateRight,
	faEllipsisVertical,
	faExpand,
	faCompress,
} from '@fortawesome/pro-regular-svg-icons'

import { XyzTransition } from '@animxyz/react'

// FUTURE: v2.2 - Allgemein verfügbar machen

/**
 * @class
 * @classdesc - Bottom Buttons for handling actions mobile friendly.
 * @example
 * <BottomButtons
 * extendAll={this.extendAll}
 * loadDevices={this.loadDevices}
 * />
 */
export default class BottomButtons extends Component {
	static contextType = Context

	state = {
		extraOptions: false,
	}

	/**
	 * @typedef {Object} PropTypes
	 * @property {Function} loadDevices - A function to initiate the process of loading devices.
	 * @property {Function} extendAll - A function to handle the extension of all items.
	 */
	static propTypes = {
		loadDevices: PropTypes.func.isRequired,
		extendAll: PropTypes.func.isRequired,
	}
	static defaultProps = {
		loadDevices: () => {},
		extendAll: () => {},
	}

	/**
	 * Toggles the 'extraOptions' state property, indicating whether extra options are currently enabled.
	 *
	 * @returns {void}
	 */
	changeExtraOptions = () => {
		this.setState({ extraOptions: !this.state.extraOptions })
	}

	render() {
		const { isRole } = this.context
		const { loadDevices, extendAll } = this.props

		return (
			<>
				<div className="mb-20" />
				<div className="fixed bottom-2 max-w-3xl w-full h-20 flex -mx-4 sm:-mx-9 md:-mx-14 px-4 sm:px-9 md:px-14">
					<div className="flex items-center justify-between w-full">
						<SingleButton
							onClick={loadDevices.bind(this)}
							icon={faRotateRight}
						/>
						{isRole('edit') && (
							<NavLink to="addDevice">
								<SingleButton icon={faPlus} />
							</NavLink>
						)}
						<div className="relative">
							<XyzTransition xyz="fade down-2">
								{this.state.extraOptions && (
									<div className="absolute bottom-20 space-y-4">
										<SingleButton
											onClick={() => {
												this.changeExtraOptions()
												extendAll(true)
											}}
											icon={faExpand}
										/>
										<SingleButton
											onClick={() => {
												this.changeExtraOptions()
												extendAll(false)
											}}
											icon={faCompress}
										/>
									</div>
								)}
							</XyzTransition>
							<SingleButton
								onClick={this.changeExtraOptions.bind(this)}
								icon={faEllipsisVertical}
							/>
						</div>
					</div>
				</div>
			</>
		)
	}
}
