import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

import Context from '#context'
import checkToast from '#toast'

import location from '#helper/location'
import { defaultHeader } from '#helper/Fetch API/request'

import ModalDefault, { ModalConfirm } from '#comp/Custom/Modal/Modal'
import Leaflet from '#comp/Leaflet'
import LoadingScreen from '#comp/LoadingScreen.jsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { faImage } from '@fortawesome/free-solid-svg-icons'

import Tenant from '../../Tenant'
import AlarmRow from '../AlarmRow'

/**
 * @class
 * @classdesc Saving location information.
 * @example
 * <SaveLocation
 * showModal={currentModal === 'location'}
 * closeModal={closeModal}
 * device={device}
 * finalRequest={finalRequest}
 * />
 */
class SaveLocation extends Component {
	static contextType = Context

	state = { useGPS: true, loadingLocation: true, location: [null, null] }

	/**
	 * @typedef {Object} PropTypes
	 * @property {Boolean} showModal - Indicates whether the location modal is visible.
	 * @property {Function} closeModal - Function to close the location modal.
	 * @property {Object} device - The device object.
	 * @property {Function} finalRequest - Function to handle the final request.
	 */
	static propTypes = {
		showModal: PropTypes.bool.isRequired,
		closeModal: PropTypes.func.isRequired,
		device: PropTypes.object.isRequired,
		finalRequest: PropTypes.func.isRequired,
	}
	static defaultProps = {
		showModal: false,
		closeModal: () => {},
		device: {},
		finalRequest: () => {},
	}

	/**
	 * Handles saving the location based on user input.
	 *
	 * @param {Boolean} camera - Indicates whether a photo should be taken or not.
	 * @returns {void}
	 */
	saveModal = (camera) => {
		const { useGPS, location } = this.state
		this.props.closeModal()
		this.props.finalRequest(camera, useGPS, location)
	}

	/**
	 * Retrieves the current location using the device's GPS.
	 *
	 * @returns {void}
	 */
	useCurrentLocation = () => {
		this.setState({ loadingLocation: true })
		location()
			.then((myLocation) => {
				this.setState({
					useGPS: true,
					loadingLocation: false,
					location: myLocation.pos,
				})
			})
			.catch((myLocation) => {
				// TODO: Toast: Error anzeigen, wenn nicht erlaubt wird.
				this.setState({
					useGPS: false,
					loadingLocation: false,
					location: [null, null],
				})
			})
	}

	/**
	 * Change if the location should be updated using the device's GPS or use the current saved location.
	 *
	 * @returns {void}
	 */
	changeLocation = () => {
		if (this.state.useGPS) {
			this.setState({
				useGPS: false,
				location: [
					this.props.device.attributes.latitude,
					this.props.device.attributes.longitude,
				],
			})
		} else {
			this.useCurrentLocation()
		}
	}

	componentDidMount = () => {
		this.useCurrentLocation()
	}

	render() {
		const { t, fetchPicture } = this.context
		const { useGPS, loadingLocation, location } = this.state
		const { showModal, closeModal, device } = this.props

		return (
			<ModalConfirm
				show={showModal}
				onClose={closeModal.bind(this)}
				denyModal={() => this.saveModal(false)}
				saveModal={() => this.saveModal(true)}
				buttonConfirm={t('devices.newPicture.newPicture')}
				buttonCancel={t('devices.newPicture.oldPicture')}
				disabled={loadingLocation}
				header
				footer={t('devices.newPicture.question')}
			>
				<div className="text-xs">
					<AlarmRow
						alarmText={t('devices.move.noticeDuration')}
						color="6"
						isUsecase={false}
						device={device}
					/>
				</div>
				{loadingLocation && <LoadingScreen.Spinner className="mt-4" />}

				{!loadingLocation && (
					<div className="mt-4">
						<Leaflet
							latitude={location[0]}
							longitude={location[1]}
						/>
						<button
							onClick={this.changeLocation.bind(this)}
							className="w-full mt-2 underline"
						>
							{useGPS
								? t('devices.location.useNoGPS')
								: t('devices.location.useGPS')}
						</button>
					</div>
				)}

				<div className="flex justify-center mt-5">
					<div
						onClick={() => fetchPicture(device.id, device.serial)}
						className="flex items-center justify-center h-10 p-2 bg-gray-100 border border-gray-800 rounded-md cursor-pointer dark:bg-gray-700 dark:border-gray-500 w-fit"
					>
						<FontAwesomeIcon icon={faImage} />
						<span className="md:mb-0.5 ml-2">
							{t('devices.newPicture.currentPicture')}
						</span>
					</div>
				</div>
			</ModalConfirm>
		)
	}
}

/**
 * @class
 * @classdesc - Managing the provisioning status of a device.
 * @example
 * <ProvDevice
 * showModal={currentModal === 'prov'}
 * closeModal={closeModal}
 * device={device}
 * provisionNetmoreGateway={this.provisionNetmoreGateway}
 * reloadPage={reloadPage}
 * />
 */
class ProvDevice extends Component {
	static contextType = Context

	state = { provIsChecked: this.props.device.status === 'enabled' }

	/**
	 * @typedef {Object} PropTypes
	 * @property {Boolean} showModal - Indicates whether the location modal is visible.
	 * @property {Function} closeModal - Function to close the location modal.
	 * @property {Object} device - The device object.
	 * @property {Function} provisionNetmoreGateway - Function to (un-)provision the gateway on netmore.
	 * @property {Function} reloadPage - Function to reload the page.
	 */
	static propTypes = {
		showModal: PropTypes.bool.isRequired,
		closeModal: PropTypes.func.isRequired,
		device: PropTypes.object.isRequired,
		provisionNetmoreGateway: PropTypes.func.isRequired,
		reloadPage: PropTypes.func.isRequired,
	}
	static defaultProps = {
		showModal: false,
		closeModal: () => {},
		device: {},
		provisionNetmoreGateway: () => {},
		reloadPage: () => {},
	}

	/**
	 * Handles the change in provisioning status.
	 *
	 * @async
	 * @returns {void}
	 */
	changeProvisioned = async () => {
		const { provIsChecked } = this.state
		const { apiFetch, instance, auth, t } = this.context
		const { device, closeModal, reloadPage, provisionNetmoreGateway } =
			this.props

		if (device.typeId === 1) {
			const provGateway = await provisionNetmoreGateway(
				Math.round(device.attributes.netmore_id),
				provIsChecked ? 'unprovision' : 'provision'
			)
			if (!provGateway) {
				return
			}
		}

		const statusBody = { status: provIsChecked ? '0' : '1' }
		const provIotaRequest = await apiFetch(
			`${instance.api}/Device/${device.id}`,
			defaultHeader(auth.access_token, 'PATCH', statusBody)
		)
		if (provIotaRequest.logout) return

		if (!provIotaRequest.ok || !provIotaRequest.data.isUpdated) {
			checkToast(t, 13007)
			return
		}

		checkToast(t, 13101)
		closeModal()
		reloadPage()
	}

	render() {
		const { t } = this.context
		const { provIsChecked } = this.state
		const { showModal, closeModal } = this.props

		return (
			<ModalConfirm
				show={showModal}
				saveModal={this.changeProvisioned.bind(this)}
				icon={faExclamationCircle}
				onClose={closeModal.bind(this)}
			>
				<p>
					{provIsChecked
						? t('devices.provisioned.modal.noProv')
						: t('devices.provisioned.modal.Prov')}
				</p>
			</ModalConfirm>
		)
	}
}

/**
 * @class
 * @classdesc - Moving a device to a different tenant.
 * @example
 * <MoveDevice
 * showModal={currentModal === 'move'}
 * closeModal={closeModal}
 * device={device}
 * provisionNetmoreGateway={this.provisionNetmoreGateway}
 * emailSettings={emailSettings}
 * />
 */
class MoveDevice extends Component {
	static contextType = Context

	state = { MoveChangeUrl: false }

	/**
	 * @typedef {Object} PropTypes
	 * @property {Boolean} showModal - Indicates whether the location modal is visible.
	 * @property {Function} closeModal - Function to close the location modal.
	 * @property {Object} device - The device object.
	 * @property {Function} provisionNetmoreGateway - Function to (un-)provision the gateway on netmore.
	 * @property {Function} reloadPage - Function to reload the page.
	 * @property {Function} emailSettings - Add current notification settings.
	 */
	static propTypes = {
		showModal: PropTypes.bool.isRequired,
		closeModal: PropTypes.func.isRequired,
		device: PropTypes.object.isRequired,
		provisionNetmoreGateway: PropTypes.func.isRequired,
		reloadPage: PropTypes.func.isRequired,
		emailSettings: PropTypes.func.isRequired,
	}
	static defaultProps = {
		showModal: false,
		closeModal: () => {},
		device: {},
		provisionNetmoreGateway: () => {},
		reloadPage: () => {},
		emailSettings: () => {},
	}

	/**
	 * Moves the device to a different tenant.
	 *
	 * @param {String} id - The ID of the target tenant.
	 * @param {Object} device - The device object to be moved.
	 * @returns {boolean} - Indicates whether the device move was successful.
	 */
	moveDevice = async (id, device) => {
		const { t, instance, apiFetch, auth } = this.context
		const { provisionNetmoreGateway, emailSettings, closeModal } =
			this.props

		const targetTenant = await apiFetch(
			`${instance.api}/Tenant/${id}`,
			auth.access_token
		)
		if (!targetTenant.ok) {
			checkToast(t, 12005)
			return false
		}

		let body = {
			// TODO: Delete this after IOTA update "move_to_tenant is disabled!"
			move_to_tenant: id,
			customer_code:
				targetTenant.data.configuration.attributes.customer_code,
		}

		// If device should be automatically unprovisioned
		if (instance.unprovisionTenants.includes(Number(id))) {
			body.status = 0
			if (device.typeId === 1) {
				const provGateway = await provisionNetmoreGateway(
					Math.round(device.attributes.netmore_id),
					'unprovision'
				)
				if (!provGateway) {
					return false
				}
			}
		}

		// TODO: Test, ob hier "true" sein muss und wie es sich verhält.
		// Add notification settings to the request body
		body = emailSettings(body, true)

		const moveDeviceBody = {
			deviceId: device.id,
			tenantId: id,
			connectChildren: true,
			connectParents: true,
		}

		const moveDeviceRequest = await apiFetch(
			`${instance.api_tenantswitcher}/TenantSwitcher`,
			defaultHeader(auth.access_token, 'POST', moveDeviceBody)
		)

		if (!moveDeviceRequest.ok) {
			checkToast(t, 15004)
			return false
		}

		const changeAttributesRequest = await apiFetch(
			`${instance.api}/Device/${device.id}`,
			defaultHeader(auth.access_token, 'PATCH', body)
		)
		if (changeAttributesRequest.logout) return false

		if (
			!changeAttributesRequest.ok ||
			!changeAttributesRequest.data.isUpdated
		) {
			checkToast(t, 15004)
			return false
		}

		checkToast(t, 15102)
		closeModal()
		this.setState({ MoveChangeUrl: true })
		return true
	}

	render() {
		const { t } = this.context
		const { MoveChangeUrl } = this.state
		const { showModal, closeModal, device } = this.props

		if (MoveChangeUrl) {
			return <Navigate to={'..'} />
		}

		return (
			<ModalDefault
				show={showModal}
				onClose={closeModal.bind(this)}
				header={t('devices.move.header')}
			>
				<div className="h-[calc(100%-10rem)] text-left">
					<div className="mb-4 text-xs">
						<AlarmRow
							alarmText={t('devices.move.noticeDuration')}
							color="6"
							isUsecase={false}
							device={device}
						/>
					</div>
					<Tenant
						onClick={(e) =>
							this.moveDevice(
								e.currentTarget.dataset.tenantid,
								device
							)
						}
					/>
				</div>
			</ModalDefault>
		)
	}
}

/**
 * @class
 * @classdesc - Managing various modals.
 * @example
 * <Modals
 * currentModal={currentModal}
 * closeModal={() => this.setState({ currentModal: null })}
 * device={device}
 * finalRequest={this.finalRequest}
 * reloadPage={this.reloadPage}
 * emailSettings={this.emailSettings}
 * />
 */
export default class Modals extends Component {
	static contextType = Context

	/**
	 * @typedef {Object} PropTypes
	 * @property {Boolean} currentModal -
	 * @property {Function} closeModal - Function to close the location modal.
	 * @property {Object} device - The device object.
	 * @property {Function} finalRequest - Function to handle the final request.
	 * @property {Function} reloadPage - Function to reload the page.
	 * @property {Function} emailSettings - Add current notification settings.
	 */
	static propTypes = {
		showModal: PropTypes.bool.isRequired,
		closeModal: PropTypes.func.isRequired,
		device: PropTypes.object.isRequired,
		finalRequest: PropTypes.func.isRequired,
		reloadPage: PropTypes.func.isRequired,
		emailSettings: PropTypes.func.isRequired,
	}
	static defaultProps = {
		showModal: false,
		closeModal: () => {},
		device: {},
		finalRequest: () => {},
		reloadPage: () => {},
		emailSettings: () => {},
	}

	/**
	 * Provisions or unprovisions a Netmore gateway based on the provided ID and action.
	 *
	 * @async
	 * @param {String} id - The ID of the Netmore gateway.
	 * @param {String} prov - The action to perform ('provision' or 'unprovision').
	 * @returns {Boolean} - Indicates whether the Netmore gateway provisioning was successful.
	 */
	provisionNetmoreGateway = async (id, prov) => {
		const { t, apiFetch } = this.context
		const netmore_url = 'https://api.blink.services/rest'
		const passwordBody = {
			password: process.env.REACT_APP_NETMORE_PASSWORD,
		}

		const netmoreTokenRequest = await apiFetch(
			netmore_url +
				'/core/login/' +
				process.env.REACT_APP_NETMORE_USERNAME,
			defaultHeader(null, 'POST', passwordBody)
		)
		if (!netmoreTokenRequest.ok || !netmoreTokenRequest.data.success) {
			checkToast(t, 11006)
			return false
		}
		const netmoreToken = 'Bearer ' + netmoreTokenRequest.data.token

		const netmoreGwRequest = await apiFetch(
			netmore_url + `/net/gateways/${id}`,
			defaultHeader(netmoreToken)
		)
		if (!netmoreGwRequest.ok || !netmoreGwRequest.status === 400) {
			checkToast(t, 13008)
			return false
		}

		const provGatewayRequest = await apiFetch(
			netmore_url + `/net/gateways/${id}/${prov}`,
			defaultHeader(netmoreToken, 'POST')
		)
		if (!provGatewayRequest.ok) {
			checkToast(t, 13007)
			return false
		}

		return true
	}

	render() {
		const {
			currentModal,
			closeModal,
			device,
			finalRequest,
			reloadPage,
			emailSettings,
		} = this.props

		return (
			<div>
				{currentModal === 'location' && (
					<SaveLocation
						showModal={currentModal === 'location'}
						closeModal={closeModal}
						device={device}
						finalRequest={finalRequest}
					/>
				)}
				{currentModal === 'prov' && (
					<ProvDevice
						showModal={currentModal === 'prov'}
						closeModal={closeModal}
						device={device}
						provisionNetmoreGateway={this.provisionNetmoreGateway}
						reloadPage={reloadPage}
					/>
				)}
				{currentModal === 'move' && (
					<MoveDevice
						showModal={currentModal === 'move'}
						closeModal={closeModal}
						device={device}
						provisionNetmoreGateway={this.provisionNetmoreGateway}
						emailSettings={emailSettings}
					/>
				)}
			</div>
		)
	}
}
