import React, { Component } from 'react'

import Context from '#context'

import { LightHR } from '#comp/Custom/Headline'

import packageJson from '../../../package.json'

/**
 * @class
 * @classdesc - Footer section
 * @example
 * <Footer />
 */
export default class Footer extends Component {
	static contextType = Context

	render() {
		const { profile, t } = this.context

		return (
			<div className="text-sm">
				{profile && (
					<p className="text-center mb-2">{profile.username}</p>
				)}
				<div className="flex flex-col space-y-2">
					<LightHR borderColor="border-black" />
					<div className="flex justify-between">
						<div>
							<span className="mr-1">version:</span>
							{packageJson.version}
						</div>
						<div>
							<span className="mr-1">©</span>
							{new Date(Date.now()).getFullYear()}
							<a
								className="underline ml-1"
								href="https://sens.at/impressum"
							>
								{t('all.imprint')}
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
