import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Context from '#context'

import { installationPlace } from '#helper/showData.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faEdit,
	faArrowRightArrowLeft,
	faSave,
	faCancel,
} from '@fortawesome/pro-light-svg-icons'

import { Button } from 'flowbite-react'

/**
 * @class
 * @classdesc - Header inside the details page
 * @example
 * <DetailsHeader
 * device={device}
 * editInputs={editInputs}
 * changeEditInputs={changeEditInputs}
 * saveInputs={this.saveInputs}
 * moveDevice={this.moveDevice}
 * />
 */
export default class DetailsHeader extends Component {
	static contextType = Context

	/**
	 * @typedef {Object} PropTypes
	 * @property {Object} device - The device object.
	 * @property {Boolean} editInputs - Boolean indicating whether the inputs are in edit mode.
	 * @property {Function} changeEditInputs - Function to change the edit inputs.
	 * @property {Function} saveInputs - Function to save the current inputs.
	 * @property {Function} moveDevice - Function to trigger the "Move Device" Modal.
	 */
	static propTypes = {
		device: PropTypes.object.isRequired,
		editInputs: PropTypes.bool.isRequired,
		changeEditInputs: PropTypes.func.isRequired,
		saveInputs: PropTypes.func.isRequired,
		moveDevice: PropTypes.func.isRequired,
	}
	static defaultProps = {
		device: {},
		editInputs: false,
		changeEditInputs: () => {},
		saveInputs: () => {},
		moveDevice: () => {},
	}

	render() {
		const { isRole } = this.context
		const { device, editInputs, changeEditInputs, saveInputs, moveDevice } =
			this.props

		return (
			<div className="flex justify-between mt-2 mb-2 flex-col xxs:flex-row">
				<div className="flex xs:font-bold flex-col truncate xxs:w-[calc(100%-150px)] mb-2 xxs:mb-0 text-center xxs:text-left">
					<div className="truncate">
						{installationPlace(device.attributes)}
					</div>
					<div>{device.serial}</div>
				</div>
				{isRole('edit') &&
					(editInputs ? (
						<div className="flex space-x-2 justify-center xxs:justify-start">
							<Button
								onClick={saveInputs.bind(this)}
								className="dark:shadow-none dark:border-white dark:border shadow-smAll text-black dark:text-white"
								color
							>
								<FontAwesomeIcon size="xl" icon={faSave} />
							</Button>
							<Button
								onClick={() => changeEditInputs(false)}
								className="dark:shadow-none dark:border-white dark:border shadow-smAll text-black dark:text-white"
								color
							>
								<FontAwesomeIcon size="xl" icon={faCancel} />
							</Button>
						</div>
					) : (
						<div className="flex space-x-2 justify-center xxs:justify-start">
							<Button
								onClick={moveDevice.bind(this)}
								className="dark:shadow-none dark:border-white dark:border shadow-smAll text-black dark:text-white"
								color
							>
								<FontAwesomeIcon
									size="xl"
									icon={faArrowRightArrowLeft}
								/>
							</Button>
							<Button
								onClick={() => changeEditInputs(true)}
								className="dark:shadow-none dark:border-white dark:border shadow-smAll text-black dark:text-white"
								color
							>
								<FontAwesomeIcon size="xl" icon={faEdit} />
							</Button>
						</div>
					))}
			</div>
		)
	}
}
