import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Context from '#context'

import { alarmColors } from '#helper/alarms'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'

/**
 * @class
 * @classdesc - Alarm Row
 * @example
 * <AlarmRow
 * alarmText={t('devices.move.noticeDuration')}
 * color="6"
 * isUsecase
 * device={device}
 * />
 */
export default class AlarmRow extends Component {
	static contextType = Context

	/**
	 * @typedef {Object} PropTypes
	 * @property {String} alarmText - The text of the alarm.
	 * @property {String} color - The color index of the alarm.
	 * @property {Boolean} [isUsecase] - Indicates whether the alarm text is an usecase alarm.
	 * @property {Object} device - The current device
	 */
	static propTypes = {
		alarmText: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		isUsecase: PropTypes.bool,
		device: PropTypes.object.isRequired,
	}
	static defaultProps = {
		alarmText: '',
		color: null,
		isUsecase: true,
		device: {},
	}

	/**
	 * Updates alarm-related state properties based on the provided device information.
	 *
	 * @param {string} alarmText - The alarm text got from API.
	 * @returns {string} - The translated alarm text
	 */
	getAlarmText = (alarmText) => {
		const { t, instance, temperatureThresholds } = this.context
		const { device } = this.props

		const { installation_place } = device.attributes
		const installationplaceTarget = temperatureThresholds.find(
			(thresholds) => thresholds.place === installation_place
		)

		if (
			installationplaceTarget &&
			instance.groupUsecases.temperature.includes(device.typeId)
		) {
			return t('alarms.usecases.' + alarmText, {
				target: installationplaceTarget.target,
			})
		}

		return t('alarms.usecases.' + alarmText)
	}

	render() {
		const { alarmText, color, isUsecase } = this.props

		return (
			<>
				{alarmText && (
					<div
						className={
							alarmColors(color).text +
							alarmColors(color).border +
							'flex items-center p-1 border border-dashed text-left rounded-md font-bold'
						}
					>
						<div>
							<FontAwesomeIcon
								icon={faExclamationCircle}
								size="lg"
							/>
						</div>
						<p className="ml-2 hyphens-auto" lang="de">
							{isUsecase ? (
								<span>{this.getAlarmText(alarmText)}</span>
							) : (
								<span>{alarmText}</span>
							)}
						</p>
					</div>
				)}
			</>
		)
	}
}
