import React, { Component } from 'react'

import Context from '#context'

import headerPhoto from '#shared/media/header.png'

import { Squash as Hamburger } from 'hamburger-react'

import Sidebar from './Sidebar'

/**
 * @class
 * @classdesc - Header section
 * @example
 * <Header />
 */
export default class Header extends Component {
	static contextType = Context

	render() {
		const { darkMode, sidebar, changeSidebar } = this.context

		return (
			<div className="relative">
				<div className="h-16 w-full relative">
					<div className="h-full absolute w-full">
						<div className="h-full absolute w-full top-0 bg-white dark:bg-gray-800"></div>
						<img
							src={headerPhoto}
							alt="Header"
							className={`${
								darkMode ? 'opacity-60' : 'opacity-90'
							} h-full w-full object-cover backdrop-blur shadow-md`}
						/>
					</div>
					<div className="absolute right-4 h-full">
						<div className="flex items-center h-full">
							<Hamburger
								color="#fff"
								duration={0.5}
								size={30}
								toggled={sidebar}
								toggle={changeSidebar.bind(this)}
							/>
						</div>
					</div>
				</div>
				<Sidebar />
			</div>
		)
	}
}
