import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Navigate, NavLink } from 'react-router-dom'

import Context from '#context'
import checkToast from '#toast'

import { delayInput } from '#helper/helper.js'

import Input from '#comp/Custom/Input'
import ScrollFooter from '#comp/Custom/Scroll/ScrollFooter'
import ScrollButton from '#comp/Custom/Scroll/ScrollButton'

import defaultValues from '#shared/backend/defaultValues.json'

import { faClockFour } from '@fortawesome/pro-light-svg-icons'

import List from './List'

/**
 * @class
 * @classdesc - he component to see, where the user can search for a specific tenant and sees a list of those tenants.
 * @example
 * <Tenant onClick={(e) => clickHandler(e)} />
 */
export default class Tenant extends Component {
	static contextType = Context

	state = {
		loading: false,
	}

	/**
	 * @typedef {Object} PropTypes
	 * @property {Function} [onClick]
	 */
	static propTypes = {
		onClick: PropTypes.func,
	}
	static defaultProps = {
		onClick: null,
	}

	t = this.context.t

	/**
	 * Handles input changes in a form field with delayed processing.
	 *
	 * @param {Event} e
	 * @returns {void}
	 */
	myInput = (e) => {
		const request = async (input) => {
			await this.context.fetchTenants(input, 12003)
			this.setState({ loading: false })
		}

		delayInput(this, request, {
			value: e.target.value,
			search: defaultValues.tenantSearch,
			checkToast: checkToast,
		})
	}

	componentDidMount = async () => {
		// If the user has a certain amount of tenants assigned.
		if (
			this.context.auth.tenant_id.length <
			defaultValues.autoloadTenantsBelow
		) {
			// Fetch all tenants assigned to the user.
			this.setState({ loading: true })
			await this.context.fetchTenants('', 12004)
			this.setState({ loading: false })
		}
	}

	componentWillUnmount = () => {
		if (!this.props.onClick) {
			this.context.setBreadcrumb('tenant', null)
		}
	}

	render() {
		const { auth, t, recentTenants } = this.context
		const { onClick } = this.props
		const { loading } = this.state

		// Forward directly, if the user has only one tenant assigned.
		if (auth.tenant_id.length === 1) {
			return <Navigate to={'' + auth.tenant_id[0]} replace />
		}

		return (
			<div>
				<div>
					<Input
						name="searchTenant"
						onChange={this.myInput.bind(this)}
					>
						{t('tenant.placeholder.input')}
					</Input>
					{recentTenants.length > 0 && (
						<ScrollFooter icon={faClockFour} bottomLine>
							{recentTenants.map((recent, i) => (
								<ScrollButton
									key={recent.id + '_recentTenants'}
									truncate
								>
									{onClick ? (
										<span
											data-tenantid={recent.id}
											onClick={this.props.onClick.bind(
												this
											)}
										>
											{recent.name}
										</span>
									) : (
										<NavLink to={recent.id}>
											{recent.name}
										</NavLink>
									)}
								</ScrollButton>
							))}
						</ScrollFooter>
					)}
				</div>
				<List onClick={onClick} loading={loading} />
			</div>
		)
	}
}
