import React, { Component } from 'react'

import Context from '#context'

import { attributesSorting } from '#helper/showData'

import Leaflet from '#comp/Leaflet'

/**
 * @class
 * @classdesc - List all attributes & handle the map logic
 * @example
 * <Listed
 * device={device}
 * appControlled={appControlled}
 * />
 */
export default class Listed extends Component {
	static contextType = Context

	/**
	 * If-Condition to display the correct DEVEUI/GWEUI prefix.
	 *
	 * @param {Object} device
	 * @returns {String} - EUI-prefix
	 */
	showEUI = (device) => {
		return device.typeId === 1 ? 'GWEUI' : 'DEVEUI'
	}

	render() {
		const { t, showModal } = this.context
		const { appControlled, device } = this.props

		return (
			<>
				<ul className="space-y-2">
					{
						<li>
							{this.showEUI(device)}: {device.edid}
						</li>
					}
					{attributesSorting(appControlled, t).map((input, i) => (
						<li key={i + "_attributesList"}>
							<p>
								{input.displayname}: {input.value}
							</p>
						</li>
					))}
				</ul>
				{!showModal && (
					<div className="!mt-4">
						<Leaflet
							latitude={device.attributes.latitude}
							longitude={device.attributes.longitude}
							hr
						/>
					</div>
				)}
			</>
		)
	}
}
