import checkToast from '#toast'

export const validateRequired = (t, allInputs, required) => {
	for (const attr of required) {
		if (
			attr !== 'installation_place' &&
			allInputs.hasOwnProperty(attr) &&
			(allInputs[attr] === '' || allInputs[attr] === null)
		) {
			checkToast(t, 15006, null, {
				attribute: t('devices.displayNames.' + attr),
			})
			return null
		}
	}
	return allInputs
}

export const validateInputs = (t, allInputs) => {
	const ThreeDecimalsRegex = /^\d+(\.\d{1,3})?$/

	if (
		(allInputs.hasOwnProperty('offset_a') &&
			!Number.isInteger(Number(allInputs.offset_a))) ||
		(allInputs.hasOwnProperty('offset_b') &&
			!Number.isInteger(Number(allInputs.offset_b)))
	) {
		const valueA = t('devices.displayNames.offset_a')
		const valueB = t('devices.displayNames.offset_b')

		checkToast(t, 13013, null, { valueA, valueB })
		return null
	}

	if (
		(allInputs.hasOwnProperty('pulse_weight_a') &&
			!ThreeDecimalsRegex.test(allInputs.pulse_weight_a)) ||
		(allInputs.hasOwnProperty('pulse_weight_b') &&
			!ThreeDecimalsRegex.test(allInputs.pulse_weight_b))
	) {
		const valueA = t('devices.displayNames.pulse_weight_a')
		const valueB = t('devices.displayNames.pulse_weight_b')

		checkToast(t, 13014, null, { valueA, valueB, decimals: 3 })
		return null
	}

	return allInputs
}

export const validateInstallationPlace = (t, allInputs, device) => {
	if (
		allInputs.installation_place === '0' ||
		allInputs.installation_place === null ||
		(allInputs.installation_place === 'Sonstiges' &&
			allInputs.installation_place2 === null)
	) {
		checkToast(t, 15003)
		return null
	}

	if (allInputs.installation_number === 'noOption') {
		checkToast(t, 15005)
		return null
	}

	if (
		allInputs.installation_place !== 'Sonstiges' &&
		allInputs.installation_number === null
	) {
		allInputs.installation_number = 1
	}

	if (
		allInputs.installation_place !== 'Sonstiges' &&
		device.attributes.hasOwnProperty('installation_place2')
	) {
		allInputs.installation_place2 = null
	}
	return allInputs
}
