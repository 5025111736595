import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

import Context from '#context'
import checkToast from '#toast'

import { defaultHeader } from '#helper/Fetch API/request'
import { filterDevices } from '#helper/Fetch API/filter'

import LoadingScreen from '#comp/LoadingScreen'

import EditDevices from '../../../Details/EditDevices'

/**
 * @class
 * @classdesc - After a successful device selection to add a device
 * @example
 * <Wrap routeEl={Success} />
 */
export default class Success extends Component {
	static contextType = Context

	state = {
		loading: true,
		device: null,
		inputs: [],
		cancelProcess: false,
	}

	/**
	 * @typedef {Object} ParamsShape
	 * @property {string} newDeviceCode - The newDeviceCode parameter of the url.
	 * @property {string} tenantId - The tenantId parameter of the url.
	 * @property {...*} [otherProps] - Additional properties that may be present in the params.
	 * @typedef {Object} PropTypes
	 * @property {ParamsShape} params
	 * @property {Object} [locations]
	 * @property {ReactNode} routeEl
	 */
	static propTypes = {
		params: PropTypes.shape({
			newDeviceCode: PropTypes.string.isRequired,
			tenantId: PropTypes.string.isRequired,
		}),
		locations: PropTypes.object,
		routeEl: PropTypes.func.isRequired,
	}
	static defaultProps = {
		params: { newDeviceCode: '', tenantId: '' },
	}

	AccessToken = this.context.auth.access_token

	/**
	 * Loads and updates device information.
	 *
	 * @async
	 * @returns {Object|null} - The loaded device object or null if there was an issue.
	 */
	loadDevice = async () => {
		const { apiFetch, instance, t } = this.context

		var device = null
		const deviceRequest = await apiFetch(
			`${instance.api}/Device/${this.state.device.id}`,
			this.AccessToken
		)
		if (deviceRequest.logout) return device

		if (deviceRequest.ok) {
			device = deviceRequest.data
		} else {
			checkToast(t, 13002)
		}
		this.setState({ device })

		return device
	}

	componentDidMount = async () => {
		const { apiFetch, instance, t } = this.context
		const { params } = this.props

		const deviceIdRequest = await apiFetch(
			`${instance.api}/Device`,
			defaultHeader(
				this.AccessToken,
				'POST',
				filterDevices(params.newDeviceCode, 0)
			)
		)
		if (deviceIdRequest.logout) return

		if (!deviceIdRequest.ok) {
			checkToast(t, 13002)
			return
		}
		if (deviceIdRequest.data.devices.length === 0) {
			checkToast(t, 13005, null, {
				edid: params.newDeviceCode,
			})
			return
		}

		const oneDevice = deviceIdRequest.data.devices[0]
		if (instance.banSearch.includes(oneDevice.typeId)) {
			checkToast(t, 13006)
			return
		}

		this.setState({
			device: oneDevice,
			loading: false,
		})
	}

	render() {
		const { t } = this.context
		const { loading, cancelProcess, device } = this.state
		const { params } = this.props

		if (loading) {
			return <LoadingScreen.Spinner className="mt-4" />
		}

		if (cancelProcess) {
			return <Navigate to="../.." replace />
		}

		return (
			<EditDevices
				title={t('all.add.addDevice')}
				device={device}
				editInputs={true}
				parentLoadDevice={this.loadDevice.bind(this)}
				changeEditInputs={(cancelProcess) =>
					this.setState({ cancelProcess })
				}
				newTenantId={params.tenantId}
				navigatePhoto={'../../device/' + device.id}
			/>
		)
	}
}
