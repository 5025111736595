import React, { Component } from 'react'
import ModalDefault from '#comp/Custom/Modal/Modal'

export default class ImageModal extends Component {
	render() {
		const { selectedPhoto, show, onClose } = this.props

		return (
			<ModalDefault
				show={show}
				onClose={() => onClose(false)}
				header={selectedPhoto.serial}
			>
				<div className="flex justify-center">
					<img
						className="h-96"
						src={selectedPhoto.data}
						alt={selectedPhoto.serial}
					/>
				</div>
			</ModalDefault>
		)
	}
}
