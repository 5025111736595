import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Context from '#context'

import checkToast from '#helper/toastHandler/checkToast'

import userGuide from '#shared/media/userguide/SENS IoT App v1_0.pdf'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faSignOut, faUser } from '@fortawesome/free-solid-svg-icons'

import { Button } from 'flowbite-react'
import { XyzTransition } from '@animxyz/react'
import DayNightToggle from 'react-day-and-night-toggle'

import Footer from './Footer'

// TODO: Alle Buttons in eine eigene Komponente - schönerer Code durch gleiche Margins.

/**
 * @class
 * @classdesc - A sidebar component that displays a navigation menu with buttons. It also displays the username of the currently logged-in user, if available.
 * @example
 * <Sidebar />
 */
export default class Sidebar extends Component {
	static contextType = Context

	render() {
		const {
			sidebar,
			t,
			changeDarkMode,
			darkMode,
			changeSidebar,
			auth,
			logout,
			changeLanguage,
			// changeFont,
			language,
			// font,
		} = this.context
		return (
			<XyzTransition xyz="fade">
				{sidebar && (
					<div className="z-[500] -pt-16 h-[calc(100vh_-_4rem)] backdrop-blur absolute w-full px-8">
						<div className="relative h-full">
							<div className="flex pt-8 justify-between">
								<div className="hover:drop-shadow-fullBlack dark:hover:drop-shadow-fullWhite">
									<DayNightToggle
										onChange={changeDarkMode}
										checked={darkMode}
										animationInactive={false}
										className="w-5"
									/>
								</div>
								<div className="flex gap-4">
									<div className="hover:drop-shadow-fullBlack dark:hover:drop-shadow-fullWhite">
										<NavLink
											to="/"
											onClick={changeSidebar.bind(this)}
										>
											<FontAwesomeIcon
												icon={faHouse}
												size="2x"
											/>
										</NavLink>
									</div>
									{auth && (
										<>
											<button
												className="cursor-not-allowed"
												onClick={() =>
													checkToast(
														this.context.t,
														10004
													)
												}
											>
												<FontAwesomeIcon
													icon={faUser}
													size="2x"
													className="opacity-60"
												/>
											</button>
											<button
												className="hover:drop-shadow-fullBlack dark:hover:drop-shadow-fullWhite"
												onClick={logout}
											>
												<FontAwesomeIcon
													icon={faSignOut}
													size="2x"
												/>
											</button>
										</>
									)}
								</div>
							</div>
							<div className="mt-6">
								{t('all.sidebar.translations.changeLanguage')}:
							</div>
							<div className="mt-2 flex flex-row space-x-2">
								<Button
									disabled={language === 'de'}
									className="w-1/2"
									onClick={() => changeLanguage('de')}
								>
									{t(
										'all.sidebar.translations.languages.german'
									)}
								</Button>
								<Button
									disabled={language === 'en'}
									className="w-1/2"
									onClick={() => changeLanguage('en')}
								>
									{t(
										'all.sidebar.translations.languages.english'
									)}
								</Button>
							</div>
							{/*<div className="mt-6">
								{t('all.sidebar.font.changeFont')}:
							</div>
							<div className="mt-2 flex flex-row space-x-2">
								<Button
									disabled={font === ''}
									className="w-1/2"
									onClick={() => changeFont('')}
								>
									{t('all.sidebar.font.default')}
								</Button>
								<Button
									disabled={font === 'montserrat'}
									className="w-1/2"
									onClick={() => changeFont('montserrat')}
								>
									Montserrat
								</Button>
							</div>*/}

							<div className="mt-6 w-full">
								<a href={userGuide}>
									<Button className="w-full">
										{t(
											'all.sidebar.translations.userGuide'
										)}
									</Button>
								</a>
							</div>

							<div className="mt-6 w-full">
								<Button
									className="w-full"
									onClick={() =>
										this.context.showWhatsNew(true)
									}
								>
									{t('all.sidebar.translations.showUpdates')}
								</Button>
							</div>

							{/* FUTURE: v2.4 - Andere Buttons */}
							{/* <Button
								color="red"
								onClick={() => window.location.reload(true)}
							>
								Force Reload
							</Button>
							<Button
								color="red"
								onClick={() => {
									logout()
									localStorage.clear()
								}}
							>
								Clear Local Storage
							</Button> */}
							<div className="absolute bottom-4 w-full">
								<Footer />
							</div>
						</div>
					</div>
				)}
			</XyzTransition>
		)
	}
}
