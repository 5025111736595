import React, { Component } from 'react'
import ModalDefault from '#comp/Custom/Modal/Modal'
import { LightHR } from '#comp/Custom/Headline'
import Context from '#context'

// DOKU:

export default class UpdateModal extends Component {
	static contextType = Context

	translationContent = (content) => {
		content.map((c) => {
			const version = c.version.join('_')
			const path = 'updates.versions.' + version + '.'
			c.headline = this.translatePath(path + 'headline')
			return c
		})
		return content
	}

	translationFeatures = (content) => {
		const features = content.features.map((feature) => {
			const version = content.version.join('_')
			const path = 'updates.versions.' + version + '.features.'

			const translatedFeatures = {
				headline: this.translatePath(path + feature + '.headline'),
				description: this.translatePath(
					path + feature + '.description'
				),
			}
			return translatedFeatures
		})

		return features
	}

	translatePath = (path) => this.context.t(path)

	// TODO: Check, when a translatation could not be found. (String wird angezeigt - Lösung finden? - Maybe check mit split(.))

	showDate = (date) => {
		const newDate = new Date(date)
		const options = {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		}

		return newDate.toLocaleString(undefined, options)
	}

	render() {
		return (
			<ModalDefault
				show={this.props.showUpdateModal}
				onClose={() => this.props.showWhatsNew(false)}
				header={this.context.t('updates.' + this.props.header)}
			>
				<div className="text-left">
					{this.translationContent(this.props.content).map(
						(content, i) => (
							<div key={JSON.stringify(content.version)}>
								<div className="flex justify-between flex-col text-center">
									<h3 className="text-xl font-bold">
										{content.headline}
									</h3>
									<div className="flex justify-center space-x-1 text-sm">
										<div>v{content.version.join('.')}</div>
										<div>|</div>
										<div className="italic">
											{this.showDate(content.updateDate)}
										</div>
									</div>
								</div>
								<div className="space-y-2 my-2">
									{this.translationFeatures(content).map(
										(feature, j) => (
											<div key={i + '_' + j + '_feature'}>
												<div className="text-sm font-bold">
													{feature.headline}
												</div>
												<ul className="text-xs">
													{feature.description
														.split('\\n')
														.map((row, k) => (
															<li
																key={
																	i +
																	j +
																	k +
																	'_description'
																}
																className="list-disc ml-6"
															>
																{row}
															</li>
														))}
												</ul>
											</div>
										)
									)}
								</div>
								{i < this.props.content.length - 1 && (
									<div className="my-2">
										<LightHR />
									</div>
								)}
							</div>
						)
					)}
				</div>
			</ModalDefault>
		)
	}
}
