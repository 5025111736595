/**
 * Asynchronously retrieves the current geolocation coordinates using the browser's navigator API.
 *
 * @async
 * @property {string|null} error - The error message if geolocation retrieval fails, or null if successful.
 * @property {Array<number>} pos - An array containing latitude and longitude if geolocation retrieval is successful.
 * @returns {Promise<Object>} - A promise that resolves with an object containing the geolocation information or rejects with an error message.
 */
const location = () => {
	var myReturn = { error: null, pos: [] }

	const myProm = new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(
			(pos) => {
				myReturn.pos = [pos.coords.latitude, pos.coords.longitude]
				resolve(myReturn)
			},
			(error) => {
				if (error.code === error.PERMISSION_DENIED)
					myReturn.error = 'location.noPermission'
				else if (error.code === error.POSITION_UNAVAILABLE)
					myReturn.error = 'location.notAvailable'
				else if (error.code === error.TIMEOUT)
					myReturn.error = 'location.timeout'
				reject(myReturn)
			}
		)
	})

	return myProm
}

export default location
