import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

import Context from '#context'
import checkToast from '#toast'

import { changeSelected } from '#helper/camera'
import { getLS } from '#helper/localStorage'
import { defaultHeader } from '#helper/Fetch API/request'

import SingleButton from '#comp/Custom/SingleBottomButton'
import Webcam, { Selector } from '#comp/Webcam'

import { faCamera, faRedo, faSave } from '@fortawesome/pro-regular-svg-icons'

/**
 * @class
 * @classdesc - Shows the camera page and handles saving the photo.
 * @example
 * <Wrap routeEl={Camera} />
 */
export default class Camera extends Component {
	static contextType = Context

	state = {
		imageData: '',
		// imageData: null,
		takePicture: false,
		startCam: false,
		selectedDeviceId: getLS('selectedCamera') || 'undefined',
		navigate: false,
	}

	/**
	 * @typedef {Object} ParamsShape
	 * @property {string} deviceId - The deviceId parameter of the url.
	 * @property {...*} [otherProps] - Additional properties that may be present in the params.
	 * @typedef {Object} PropTypes
	 * @property {ParamsShape} params
	 * @property {Object} [locations]
	 * @property {ReactNode} routeEl
	 */
	static propTypes = {
		params: PropTypes.shape({
			deviceId: PropTypes.string.isRequired,
		}),
		locations: PropTypes.object,
		routeEl: PropTypes.func.isRequired,
	}
	static defaultProps = {
		params: { deviceId: '' },
	}

	// FUTURE: v2.5 - Aktuelles Bild einblenden und neues UI für "Delete Photo" schreiben.

	/**
	 * Saves the captured photo to the server.
	 *
	 * @async
	 * @returns {void}
	 */
	savePhoto = async () => {
		const { apiFetch, instance, auth, t } = this.context

		const requestBody = {
			contentType: 'image/jpeg',
			content: this.state.imageData,
		}
		const myPhotoRequest = await apiFetch(
			`${instance.api}/Device/${this.props.params.deviceId}/attachment`,
			defaultHeader(auth.access_token, 'PUT', requestBody)
		)
		if (myPhotoRequest.logout) return

		// FUTURE: v3.0 - Bug in IOTA. Success sollte nicht leer sein. (Code: 204)
		if (!myPhotoRequest.ok && myPhotoRequest.status !== 200) {
			checkToast(t, 17003)
		} else {
			checkToast(t, 17101)
		}

		this.setState({ navigate: true })
	}

	render() {
		const { imageData, takePicture, startCam, selectedDeviceId, navigate } =
			this.state
		const { params } = this.props

		if (navigate) {
			return <Navigate to={'../device/' + params.deviceId} />
		}

		// No Image was taken yet
		if (imageData === '') {
			return (
				<div className="flex justify-center flex-col">
					<Selector
						selectedDeviceId={selectedDeviceId}
						startScanner={() =>
							this.setState({ startCam: !startCam })
						}
						changeSelected={(id) =>
							this.setState(changeSelected(id))
						}
						startCam={startCam}
					/>
					{startCam && (
						<div className="flex justify-center w-full flex-col items-center">
							<div className="fixed bottom-2 max-w-3xl w-full justify-center h-20 flex -mx-4 sm:-mx-9 md:-mx-14 px-4 sm:px-9 md:px-14 z-[9999]">
								<SingleButton
									onClick={() =>
										this.setState({ takePicture: true })
									}
									icon={faCamera}
								/>
							</div>

							<Webcam
								deviceId={selectedDeviceId}
								takePicture={takePicture}
								tookImage={(imageData) =>
									this.setState({
										imageData,
										takePicture: false,
									})
								}
							/>
						</div>
					)}
				</div>
			)
		}

		return (
			<div className="flex justify-center flex-col items-center">
				<img
					className="rounded-lg"
					height={480}
					width={640}
					src={imageData}
					alt="image_Camera"
				/>
				<div className="fixed bottom-2 max-w-3xl w-full justify-evenly h-20 flex -mx-4 sm:-mx-9 md:-mx-14 px-4 sm:px-9 md:px-14">
					<SingleButton
						onClick={() => this.setState({ imageData: '' })}
						icon={faRedo}
					/>
					<SingleButton
						color="dark:bg-green-600 dark:hover:bg-green-700 bg-green-700 hover:bg-green-800"
						onClick={() => this.savePhoto(imageData)}
						icon={faSave}
					/>
				</div>
			</div>
		)
	}
}
