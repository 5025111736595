/**
 * Returns a function that checks if an attribute's name is not equal to a specified attribute.
 *
 * @param {String} attr - The attribute name to compare.
 * @returns {Function} - The function to check if an attribute's name is not equal to the specified attribute.
 */
const isNotAttribute = (attr) => {
	return (a) => a.name !== attr
}

/**
 * Returns a function that checks if an attribute's name is equal to a specified attribute.
 *
 * @param {String} attr - The attribute name to compare.
 * @returns {Function} - The function to check if an attribute's name is equal to the specified attribute.
 */
const isAttribute = (attr) => {
	return (a) => a.name === attr
}

/**
 * Adds a new attribute to the top of the attribute list if a specific attribute is present.
 *
 * @param {Array<Object>} attr - The array of attributes.
 * @param {Array<Object>} newAttr - The array of new attributes.
 * @param {String} singleAttr - The specific attribute to check.
 * @returns {Array<Object>} - The modified array of attributes.
 */
const addAttributeToTop = (attr, newAttr, singleAttr) => {
	if (!attr.find(isAttribute(singleAttr))) {
		return attr
	}
	attr = attr.filter(isNotAttribute(singleAttr))
	attr.unshift(newAttr.find(isAttribute(singleAttr)))
	return attr
}

/**
 * Returns a formatted timestamp based on the provided timestamp string.
 *
 * @param {String} ts - The timestamp string.
 * @returns {String} - The formatted timestamp.
 */
export const lastTimestamp = (ts) => {
	if (ts === '0' || ts === null) {
		return ''
	}
	const date = new Date(ts)
	const options = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	}

	return date.toLocaleString(undefined, options)
}

/**
 * Returns the installation place string based on the provided attributes.
 *
 * @param {Object} attr - The attributes object.
 * @returns {String} - The installation place string.
 */
export const installationPlace = (attr) => {
	if (
		attr.installation_place &&
		attr.installation_place !== '0' &&
		attr.installation_place !== 'Sonstiges'
	) {
		return (
			attr.installation_place +
			(attr.installation_number ? ' ' + attr.installation_number : '')
		)
	} else if (attr.installation_place === 'Sonstiges') {
		return attr.installation_place2
	}
	return ''
}

/**
 * Sorts and filters attributes based on specific criteria and localization.
 *
 * @param {Array<Object>} attr - The array of attributes to sort and filter.
 * @param {Function} t - The translation function for localization.
 * @returns {Array<Object>} - The sorted and filtered array of attributes.
 */
export const attributesSorting = (attr, t) => {
	// attr: [{name: '', value: ''}]
	const newAttr = attr

	attr.sort((a, b) => {
		return a.name.localeCompare(b.name)
	})

	attr = addAttributeToTop(attr, newAttr, 'installation_place2')
	attr = addAttributeToTop(attr, newAttr, 'installation_number')
	attr = addAttributeToTop(attr, newAttr, 'installation_place')

	attr = attr.filter(isNotAttribute('latitude'))

	attr = attr.filter(isNotAttribute('longitude'))

	if (attr.find(isAttribute('installation_place'))?.value === 'Sonstiges') {
		attr = attr.filter(isNotAttribute('installation_number'))
	} else {
		attr = attr.filter(isNotAttribute('installation_place2'))
	}
	attr.map((a) => {
		a.displayname = t('devices.displayNames.' + a.name)
		return a
	})
	return attr
}

/**
 * Gets the app-controlled attributes for a device based on its device type.
 *
 * @param {Object} devicetype - The device type object.
 * @param {Object} device - The device object.
 * @returns {Object} - An object containing the inputs and appControlled attributes.
 */
export const getAppControlled = (devicetype, device) => {
	let appControlled = devicetype.attributes.filter((attr) => {
		return attr.category === 'app-controlled'
	})
	appControlled.map((type) => (type.value = device.attributes[type.name]))

	let inputs = []
	appControlled.forEach((appC) => {
		inputs.push({
			displayname: appC.displayname,
			name: appC.name,
			value: appC.value,
			catalogue: appC.catalogue,
		})
	})

	return { inputs, appControlled }
}

/**
 * Returns the localized app status string based on the provided status and translation function.
 *
 * @param {String} status - The status string.
 * @param {Function} t - The translation function for localization.
 * @returns {String} - The localized app status string.
 */
export const appStatus = (status, t) => {
	if (status) {
		if (
			status.split('.')?.length > 1 &&
			isNaN(Number(status.split('.')[0]))
		) {
			return t('alarms.usecases.' + status)
		}
		return status
	} else {
		return t('devices.noStatus')
	}
}
