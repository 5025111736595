/**
 * Generates a filter object for searching entities based on the input.
 *
 * @param {String} input - The search input.
 * @param {Number} page - The page number for paginated results.
 * @param {Number} pageSize - The number of items per page.
 * @returns {Object} - The filter object for the API request.
 */
const filter = (input, page, pageSize) => {
	pageSize = pageSize || 100

	return {
		page: page,
		pageSize: pageSize,
		filter: {
			condition: {
				'@operator': 'or',
				column: [
					{
						'@datatype': 'string',
						'@name': 'name',
						'@operator': 'ilike',
						'@relative': 'False',
						'@value': `%${input}%`,
					},
					// For the tenant config attributes
					...['address', 'zip', 'city', 'market_id'].map((field) => ({
						'@datatype': 'string',
						'@name': 'configuration',
						'@operator': 'like',
						'@value': `%${field}": "${input}%`,
					})),
				],
			},
			column: [],
		},
	}
}

/**
 * Generates a filter object for searching devices based on the input.
 *
 * @param {String} input - The search input.
 * @param {Number} page - The page number for paginated results.
 * @param {Number} pageSize - The number of items per page.
 * @returns {Object} - The filter object for the API request.
 */
export const filterDevices = (input, page, pageSize) => {
	pageSize = pageSize || 100

	return {
		page: page,
		pageSize: pageSize,
		filter: {
			condition: {
				'@operator': 'or',
				column: [
					{
						'@datatype': 'string',
						'@name': 'edid',
						'@operator': 'like',
						'@value': `%${input}%`,
					},
					{
						'@datatype': 'string',
						'@name': 'serial',
						'@operator': 'like',
						'@value': `%${input}%`,
					},
				],
			},
		},
	}
}

export default filter
