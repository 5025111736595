import { saveLS, removeLS } from '#helper/localStorage'

import checkToast from '#toast'

/**
 * Changes the selected camera device and updates localStorage accordingly.
 *
 * @param {String|null} id - The ID of the selected camera device. Use 'undefined' to clear the selection.
 * @returns {Object} - An object containing the updated state properties: { selectedDeviceId, startCam }
 */
export const changeSelected = (id) => {
	if (id === 'undefined') {
		removeLS('selectedCamera')
	} else {
		saveLS('selectedCamera', id)
	}
	return { selectedDeviceId: id, startCam: false }
}

/**
 * Asynchronously retrieves and returns the list of available video input devices.
 *
 * @async
 * @param {Function} t - The translation function for localization.
 * @returns {Promise<Array<Object>>} - A promise that resolves with an array of video input devices.
 */
export const getMediaDevices = async (t) => {
	if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
		try {
			await navigator.mediaDevices.getUserMedia({
				video: true,
				audio: false,
			})

			const devices = await navigator.mediaDevices.enumerateDevices()
			const videoDevices = devices.filter(
				(device) => device.kind === 'videoinput'
			)

			return videoDevices
		} catch (error) {
			checkToast(t, 16001)
			console.error('Error when accessing devices: ', error)
		}
	} else {
		checkToast(t, 16002)
	}
}

export default getMediaDevices
