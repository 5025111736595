import React, { Component } from 'react'

import Context from '#context'

import { getLS, saveLS } from '#helper/localStorage'

import defaultValues from '#shared/backend/defaultValues.json'
import updates from '#shared/backend/updates.json'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import packageJson from '../package.json'
import Router from './components/Home/Router'
import UpdateModal from './components/Home/UpdateModal'

export default class App extends Component {
	state = {
		darkMode: false,
		progress: 1,
		sidebar: false,
		language: null,
		showModal: false,
		showUpdateModal: false,
		whatsNew: null,
		updateModalHeader: 'header',
		font: '',
	}

	/**
	 * Set the html root to the correct theme and save it to localStorage
	 *
	 * @param {Boolean} darkMode
	 * @returns {void}
	 */
	handleDarkMode = (darkMode) => {
		const htmlRoot = window.document.getElementById('html-root')
		// const myArray = ['bg-gray-900', 'dark']

		htmlRoot.className = darkMode ? 'bg-gray-900 dark' : 'bg-white'
		saveLS('darkMode', darkMode)
		this.setState({ darkMode: darkMode })
	}

	/**
	 * Change state of modal so you can work with that in the whole application.
	 *
	 * @param {Boolean} state
	 * @returns {void}
	 */
	openModal = (state) => {
		this.setState({ showModal: state })
	}

	/**
	 * Handle Progress bar
	 *
	 * @param {Number} progress - current progress value
	 * @returns {void}
	 */
	handleProgressbar = (progress) => {
		this.setState({ progress: progress })
	}

	/**
	 * Initializes the dark mode based on the stored preference in local storage.
	 * If no preference is found, it uses the default dark mode setting.
	 *
	 * @returns {void}
	 */
	initDarkMode = () => {
		var darkMode = this.state.darkMode

		// Check local storage for dark mode preference
		if (getLS('darkMode') !== null) {
			darkMode = getLS('darkMode') === defaultValues.darkMode.toString()
		}

		// Apply dark mode setting
		this.handleDarkMode(darkMode)
	}

	/**+
	 * Change the current Language (init defined on backend/defaultValues.json)
	 * @param {String} language - Countrycode
	 * @returns {void}
	 */
	changeLanguage = (language) => {
		if (!language) {
			if (defaultValues.allLanguages.includes(navigator.language)) {
				language = navigator.language
			} else {
				language = defaultValues.language
			}
		}
		document.documentElement.lang = language
		this.props.i18n.changeLanguage(language)
		this.setState({ language })
		saveLS('language', language)
	}

	/**
	 * Changes the font used in the application.
	 * @param {String} font - The font to set. If not provided, the default font will be used.
	 * @returns {void}
	 */
	changeFont = (font) => {
		if (!font) {
			font = defaultValues.font
		}
		this.setState({ font })
		saveLS('font', font)
	}

	/**
	 * Adds items to the update modal based on the specified version and options.
	 * @param {String} version - The version to compare in JSON format.
	 * @param {Boolean} onlyCurrentVersion - Indicates whether only items of the current version should be selected.
	 * @returns {Array} - An array of items to be added to the update modal.
	 */
	addToUpdateModal = (version, onlyCurrentVersion) => {
		if (!version) {
			return updates
		}

		version = JSON.parse(version)

		return updates.filter((obj) => {
			if (onlyCurrentVersion) {
				// Select only items of the current version
				return (
					obj.version.slice(0, 3).join('.') ===
					version.slice(0, 3).join('.')
				)
			} else {
				// Select items that are greater than the specified version
				return obj.version > version
			}
		})
	}

	/**
	 * Displays the "What's New" modal with relevant updates based on the specified version and options.
	 *
	 * @param {Boolean} state - The state indicating whether to show or hide the "What's New" modal.
	 * @param {String} version - The version to use for fetching updates. Can be in JSON format.
	 * @param {Boolean} onlyCurrentVersion - Indicates whether to show only updates for the current version.
	 * @returns {void} - No explicit return value.
	 */
	showWhatsNew = (state, version, onlyCurrentVersion) => {
		let whatsNew = []

		// Determine the updates to display based on the specified version and options
		if (version && onlyCurrentVersion) {
			whatsNew = this.addToUpdateModal(version, onlyCurrentVersion)
		} else if (version) {
			whatsNew = this.addToUpdateModal(version)
		} else {
			whatsNew = this.addToUpdateModal()
		}

		if (whatsNew.length > 0) {
			this.openModal(state)
			this.setState({ showUpdateModal: state, whatsNew })
		}

		if (!state) {
			this.setState({ updateModalHeader: 'header' })
		}
	}

	/**
	 * Compares the saved version with the current version and displays the "What's New" modal if there is a new update.
	 *
	 * @param {String|null} savedVersion - The previously saved version in JSON string format.
	 * @param {Boolean} newUpdate - Indicates whether there is a new update to display.
	 * @returns {void} - No explicit return value.
	 */
	compareWhatsNew = (savedVersion, newUpdate) => {
		const currentVersion = packageJson.version
			.split('.')
			.map((v) => Number(v))

		// FUTURE: v2.2 - Do not show version update when never opened before.
		// if (savedVersion === null) {
		//     return;
		// }

		// Check if the saved version is different from the current version
		// if (savedVersion !== JSON.stringify(currentVersion)) {
		if (
			savedVersion === null ||
			savedVersion !== JSON.stringify(currentVersion)
		) {
			if (newUpdate) {
				this.setState({
					updateModalHeader: 'newUpdate',
				})
			}

			const versionToDisplay =
				savedVersion || JSON.stringify(currentVersion)
			this.showWhatsNew(true, versionToDisplay, !savedVersion)
			saveLS('version', currentVersion)
		}
	}

	// init Dark, language, font & updateModal on load
	componentDidMount = () => {
		this.initDarkMode()
		this.changeLanguage(getLS('language'))
		this.changeFont(getLS('font'))
		this.compareWhatsNew(getLS('version'), true)
	}

	// FUTURE: v2.3 - Alle "helper" auf einen Context umwickeln (Performace?)

	render() {
		const {
			darkMode,
			progress,
			sidebar,
			language,
			font,
			showModal,
			showUpdateModal,
			whatsNew,
			updateModalHeader,
		} = this.state
		const { t } = this.props
		return (
			<Context.Provider
				value={{
					darkMode: darkMode,
					changeDarkMode: () => {
						this.handleDarkMode(!darkMode)
					},
					progress: progress,
					handleProgressbar: this.handleProgressbar.bind(this),
					sidebar: sidebar,
					changeSidebar: () => {
						this.setState({ sidebar: !sidebar })
					},
					t: t,
					changeLanguage: (lang) => {
						this.changeLanguage(lang)
					},
					language: language,
					changeFont: (font) => this.changeFont(font),
					font: font,
					openModal: this.openModal,
					showModal: showModal,
					showWhatsNew: this.showWhatsNew,
				}}
			>
				<div className={'mx-auto h-full shadow-md max-w-3xl ' + font}>
					<div className="h-full min-h-screen bg-white dark:bg-gray-800 dark:text-white">
						<Router />
						{showUpdateModal && (
							<UpdateModal
								showUpdateModal={showUpdateModal}
								showWhatsNew={this.showWhatsNew}
								content={whatsNew}
								header={updateModalHeader}
							/>
						)}
					</div>
				</div>

				{/* FUTURE: v2.2 - Update Toast auf Flowbite */}
				<ToastContainer
					position="top-right"
					autoClose={5000}
					// autoClose={false}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable
					pauseOnHover={false}
					limit={1}
					theme={darkMode ? 'dark' : 'light'}
				/>
			</Context.Provider>
		)
	}
}
