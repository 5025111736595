/**
 * @typedef {Object} ColorScheme
 * @property {String} label - The color scheme for the label.
 * @property {String} input - The color scheme for the input.
 * @property {String} border - The color scheme for the border.
 */

/**
 * Gets the color scheme for the input element based on the current state.
 *
 * @param {Boolean} error If error color should be visible.
 * @param {Boolean} success If success color should be visible.
 * @param {String} color Sets color to the current string. (not available currently) FUTURE: v4.0 - get more colors
 * @returns {ColorScheme} The color scheme for the input element.
 */
export const focusColor = (error, success) => {
	// Define default color values for the border, input, text and label
	let input =
		'focus:border-blue-400 dark:focus:border-blue-400 placeholder-shown:border-gray-500 dark:placeholder-shown:border-gray-400 placeholder-shown:border-t-gray-500 dark:placeholder-shown:border-t-gray-400'
	let label =
		'before:border-gray-500 before:dark:border-gray-400 after:border-gray-500 dark:after:border-gray-400 peer-placeholder-shown:text-gray-500 dark:peer-placeholder-shown:text-gray-400 peer-focus:before:border-blue-400 dark:peer-focus:before:border-blue-400 peer-focus:after:border-blue-400 dark:peer-focus:after:border-blue-400 peer-focus:text-blue-400 dark:peer-focus:text-blue-400 before:border-gray-500 dark:before:border-gray-400 after:border-gray-500 dark:after:border-gray-400'
	let border = 'border-gray-500 dark:border-gray-400'
	let text = 'text-gray-500 dark:text-gray-400'

	if (error) {
		input =
			'focus:border-red-600 dark:focus:border-red-500 placeholder-shown:border-red-600 dark:placeholder-shown:border-red-500 placeholder-shown:border-t-red-600 dark:placeholder-shown:border-t-red-500'
		label =
			'before:border-red-600 before:dark:border-red-500 after:border-red-600 dark:after:border-red-500 peer-placeholder-shown:text-red-600 dark:peer-placeholder-shown:text-red-500 peer-focus:before:border-red-600 dark:peer-focus:before:border-red-500 peer-focus:after:border-red-600 dark:peer-focus:after:border-red-500 peer-focus:text-red-600 dark:peer-focus:text-red-500 before:border-red-600 dark:before:border-red-500 after:border-red-600 dark:after:border-red-500'
		border = 'border-red-600 dark:border-red-500'
		text = 'text-red-600 dark:text-red-500'
	}

	if (success) {
		input =
			'focus:border-green-600 dark:focus:border-green-500 placeholder-shown:border-green-600 dark:placeholder-shown:border-green-500 placeholder-shown:border-t-green-600 dark:placeholder-shown:border-t-green-500'
		label =
			'before:border-green-600 before:dark:border-green-500 after:border-green-600 dark:after:border-green-500 peer-placeholder-shown:text-green-600 dark:peer-placeholder-shown:text-green-500 peer-focus:before:border-green-600 dark:peer-focus:before:border-green-500 peer-focus:after:border-green-600 dark:peer-focus:after:border-green-500 peer-focus:text-green-600 dark:peer-focus:text-green-500 before:border-green-600 dark:before:border-green-500 after:border-green-600 dark:after:border-green-500'
		border = 'border-green-600 dark:border-green-500'
		text = 'text-green-600 dark:text-green-500'
	}

	label = text + ' ' + label
	input = border + ' ' + input

	return { input, label, border, text }
}
