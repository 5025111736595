/**
 * A utility function that returns a promise resolving when the component state is updated.
 *
 * @param {Object} thisObj - The reference to the component.
 * @param {Object} newState - The new state object to set.
 * @returns {Promise} - A promise that resolves when the state is updated.
 */
export const promisedSetState = (thisObj, newState) =>
	new Promise((resolve) => thisObj.setState(newState, resolve))

/**
 * Delays input processing based on specified criteria and triggers a request function.
 *
 * @param {Object} thisObj - The reference to the component.
 * @param {Function} request - The function to perform after the delay.
 * @param {Object} options - Options for delay input behavior.
 * @param {String} options.value - The input value.
 * @param {Object} options.search - Search configuration.
 * @param {Function} options.checkToast - The function to display a toast message.
 * @returns {void}
 */
let delayEnterChars, delayFetch
export const delayInput = (thisObj, request, { value, search, checkToast }) => {
	clearTimeout(delayEnterChars)
	clearTimeout(delayFetch)

	if (value.length < search.minLength) {
		delayEnterChars = setTimeout(() => {
			checkToast(thisObj.context.t, 12002, null, {
				minChars: search.minLength,
			})
		}, search.timeout)
		return
	}

	thisObj.setState({ loading: true })

	delayFetch = setTimeout(() => request(value), search.timeout)
}
