import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'

import Context from '#context'
import checkToast from '#toast'

import { delayInput } from '#helper/helper.js'
import { defaultHeader } from '#helper/Fetch API/request'
import { filterDevices } from '#helper/Fetch API/filter'

import defaultValues from '#shared/backend/defaultValues.json'

import LoadingScreen from '#comp/LoadingScreen'
import Headline from '#comp/Custom/Headline'
import Input from '#comp/Custom/Input'
import { LightHR } from '#comp/Custom/Headline'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/pro-light-svg-icons'

import { Button } from 'flowbite-react'

import Scanner from './Scanner'

/**
 * @class
 * @classdesc - Selector for searching devices or scan a QR-Code
 * @example
 * <SelectFinder />
 */
export default class SelectFinder extends Component {
	static contextType = Context

	state = {
		code: null,
		scanner: false,
		searching: false,
		loading: false,
		devices: [],
		afterSearch: false,
		cancelInstallation: false,
	}

	/**
	 * Sets the 'code' state property to the provided value.
	 *
	 * @param {any} code - The value to set as the 'code' state.
	 * @returns {void}
	 */
	setCode = (code) => {
		this.setState({ code })
	}

	/**
	 * Handles input events, triggers device search, and updates the component state.
	 *
	 * @param {Object} e - The input event object.
	 * @returns {void}
	 */
	myInput = (e) => {
		const { t, apiFetch, instance, auth } = this.context

		const request = async (input) => {
			var devices = []
			const deviceRequest = await apiFetch(
				`${instance.api}/Device`,
				defaultHeader(
					auth.access_token,
					'POST',
					filterDevices(input, 0)
				)
			)
			if (deviceRequest.logout) return

			if (deviceRequest.ok) {
				devices = deviceRequest.data.devices
			} else {
				checkToast(t, 13012)
			}

			if (deviceRequest.data.total_count > deviceRequest.data.pageSize) {
				checkToast(t, 13010)
			}

			this.setState({
				devices,
				loading: false,
				afterSearch: true,
			})
		}

		delayInput(this, request, {
			value: e.target.value,
			search: defaultValues.deviceSearch,
			checkToast,
		})
	}

	render() {
		const {
			code,
			cancelInstallation,
			scanner,
			searching,
			loading,
			devices,
			afterSearch,
		} = this.state
		const { t } = this.context

		if (code) {
			return <Navigate to={'./' + code} replace />
		}
		if (cancelInstallation) {
			return <Navigate to="../.." replace />
		}
		return (
			<div>
				<Headline hr>{t('all.add.addDevice')}</Headline>

				<div className="flex mb-4 gap-2 flex-col xxs:flex-row">
					<div className="flex gap-2 flex-col w-full xs:flex-row order-2 xxs:order-1">
						<Button
							disabled={scanner}
							color="success"
							className="w-full xs:w-1/2"
							onClick={() =>
								this.setState({
									scanner: true,
									searching: false,
								})
							}
						>
							{t('devices.search.scanner')}
						</Button>
						<Button
							disabled={searching}
							color="success"
							className="w-full xs:w-1/2"
							onClick={() =>
								this.setState({
									scanner: false,
									searching: true,
								})
							}
						>
							{t('devices.search.search')}
						</Button>
					</div>
					<div className="flex items-center order-1 xxs:order-2">
						<Button
							onClick={() =>
								this.setState({
									cancelInstallation: true,
								})
							}
							className="w-full xs:w-auto !h-full dark:shadow-none dark:border-white dark:border shadow-smAll text-black dark:text-white"
							color
						>
							<FontAwesomeIcon size="xl" icon={faCancel} />
						</Button>
					</div>
				</div>

				{scanner && <Scanner setCode={this.setCode} />}
				{searching && (
					<div>
						<Input
							name="searchDevice"
							onChange={this.myInput.bind(this)}
						>
							{t('devices.search.searchDeviceInput')}
						</Input>
						{loading && <LoadingScreen.Spinner className="mt-4" />}
						{!loading &&
							devices.map((device) => (
								<div
									onClick={() => this.setCode(device.edid)}
									className="cursor-pointer"
									key={device.id}
								>
									<div className="py-2.5 truncate flex flex-col relative">
										<span className="truncate">
											{device.serial} - {device.edid}
										</span>
									</div>
									<LightHR />
								</div>
							))}
						{!loading && afterSearch && devices.length === 0 && (
							<div className="text-center mt-4">
								{t('devices.noDevicesInList')}
							</div>
						)}
					</div>
				)}
			</div>
		)
	}
}
