import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

import Context from '#context'
import checkToast from '#toast'

import { defaultHeader } from '#helper/Fetch API/request'
import { getAppControlled } from '#helper/showData'
import { promisedSetState } from '#helper/helper'
import { alarmLogic } from '#helper/alarms'
import {
	validateInputs,
	validateInstallationPlace,
	validateRequired,
} from '#helper/validateInputs'

import ConnectionBars from '#comp/ConnectionBars'
import LoadingScreen from '#comp/LoadingScreen'
import Headline from '#comp/Custom/Headline'
import Toggle from '#comp/Custom/Toggle'
import Notification from '#comp/Custom/Notification'

import DetailsHeader from './DetailsHeader'
import Inputs from './Inputs/Inputs'
import Listed from './Listed'
import AlarmRow from '../AlarmRow'
import StatusRow from './StatusRow'
import Modals from './Modals'
import Downlinks from './Downlinks'

/**
 * @class
 * @classdesc - Manage and edit device details.
 * @example
 * <EditDevices
 * changeEditInputs={this.changeEditInputs}
 * clickDownlink={this.clickDownlink}
 * device={device}
 * editInputs={editInputs}
 * parentLoadDevice={this.parentLoadDevice}
 * navigatePhoto={'.'}
 * newTenantId={this.props.params.tenantId}
 * title={this.context.t('bread.details')}
 * />
 */
export default class EditDevices extends Component {
	static contextType = Context

	state = {
		alarmText: null,
		appControlled: [],
		currentModal: null,
		deviceType: {},
		devices: [],
		inputs: [],
		loading: true,
		mails: {},
		ready2Patch: null,
	}

	/**
	 * @typedef {Object} PropTypes
	 * @property {Function} changeEditInputs - Function to change the edit inputs.
	 * @property {Function} [clickDownlink] - Function to handle click on downlink.
	 * @property {Object} device - The device object.
	 * @property {Boolean} editInputs - Boolean indicating whether the inputs are in edit mode.
	 * @property {Function} parentLoadDevice - Function to load the parent device.
	 * @property {String} navigatePhoto - The URL for navigating to photos.
	 * @property {String} newTenantId - The ID of the new tenant.
	 * @property {String} title - The title of the component.
	 */
	static propTypes = {
		changeEditInputs: PropTypes.func.isRequired,
		clickDownlink: PropTypes.func,
		device: PropTypes.object.isRequired,
		editInputs: PropTypes.bool.isRequired,
		parentLoadDevice: PropTypes.func.isRequired,
		navigatePhoto: PropTypes.string.isRequired,
		newTenantId: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
	}
	static defaultProps = {
		changeEditInputs: () => {},
		clickDownlink: null,
		device: {},
		editInputs: false,
		navigatePhoto: '',
		newTenantId: null,
		parentLoadDevice: () => {},
		title: '',
	}

	AccessToken = this.context.auth.access_token

	required = [
		'installation_place',
		'offset_a',
		'offset_b',
		'pulse_weight_a',
		'pulse_weight_b',
	]

	/**
	 * Adjust email settings based on the device type and tenant change.
	 *
	 * @param {Object} body - The current body of the email settings.
	 * @param {Boolean} [tenantWillChange] - Flag indicating whether the tenant is changing.
	 * @returns {Object} - Updated email settings.
	 */
	emailSettings = (body, tenantWillChange) => {
		const { deviceType, mails } = this.state
		const alarmActive = deviceType.attributes.find(
			(type) => type.name === 'alarm_active'
		)
		const alarmEmails = deviceType.attributes.find(
			(type) => type.name === 'alarm_emails'
		)
		if (
			alarmActive &&
			alarmEmails &&
			this.props.device.attributes.alarm_emails
		) {
			if (tenantWillChange) {
				body.alarm_active = alarmActive.defaultvalue
				body.alarm_emails = alarmEmails.defaultvalue
			} else {
				body.alarm_active = mails.alarm_active ? '1' : '0'
				body.alarm_emails = mails.alarm_emails.join(';')
			}
		}
		return body
	}

	/**
	 * Update inputs based on current input.
	 *
	 * @param {Object} input - input with { [name]: value } format.
	 * @returns {void}
	 */
	updateInputs = (input) => {
		const { inputs } = this.state
		const entries = Object.entries(input)
		const allOthers = inputs.filter((i) => i.name !== entries[0][0])
		const finds = inputs.find((i) => i.name === entries[0][0])

		allOthers.push({
			name: finds.name,
			value: entries[0][1],
			displayname: finds.displayname,
			catalogue: finds.catalogue,
		})

		this.setState({ inputs: allOthers })
	}

	/**
	 * Initialize the component by loading necessary data.
	 *
	 * @async
	 * @param {Object} device - The device object for which data is being loaded.
	 * @returns {Promise<void>}
	 */
	initLoad = async (device) => {
		const { t, apiFetch, instance } = this.context

		this.setState({
			alarmText: alarmLogic(t, device)?.alarmText || null,
		})

		const oneDeviceTypeRequest = await apiFetch(
			`${instance.api}/DeviceType/${device.typeId}`,
			this.AccessToken
		)
		if (!oneDeviceTypeRequest.ok) {
			checkToast(t, 13004)
			return
		}
		const deviceType = oneDeviceTypeRequest.data

		const { appControlled, inputs } = getAppControlled(deviceType, device)

		const allDevicesInTenantRequest = await apiFetch(
			`${instance.api}/Device?tenantId=${this.props.newTenantId}`,
			this.AccessToken
		)
		if (!allDevicesInTenantRequest.ok) {
			checkToast(t, 13001)
			return
		}
		const devices = allDevicesInTenantRequest.data.devices

		await promisedSetState(this, {
			appControlled,
			inputs,
			loading: false,
			devices,
			deviceType,
			mails: {
				alarm_active: device.attributes.alarm_active === '1',
				alarm_emails: device.attributes.alarm_emails?.split(';') || [],
			},
		})
	}

	/**
	 * Reload the page by fetching the device again.
	 *
	 * @async
	 * @returns {Promise<void>}
	 */
	reloadPage = async () => {
		const device = await this.props.parentLoadDevice()
		if (device) {
			this.setState({ loading: true, ready2Patch: null })
			this.props.changeEditInputs(false)
			await this.initLoad(device)
		}
	}

	/**
	 * Make the final request to update device attributes.
	 *
	 * @async
	 * @param {boolean} camera - Flag indicating whether to use the camera.
	 * @param {boolean} useGPS - Flag indicating whether to use GPS coordinates.
	 * @param {Array<number>} location - The GPS coordinates [latitude, longitude].
	 * @returns {Promise<void>}
	 */
	finalRequest = async (camera, useGPS, location) => {
		const { t, apiFetch, instance } = this.context
		const { ready2Patch } = this.state
		const { device, newTenantId } = this.props

		if (useGPS) {
			ready2Patch.latitude = location[0].toString()
			ready2Patch.longitude = location[1].toString()
		} else {
			ready2Patch.latitude = device.attributes.latitude
			ready2Patch.longitude = device.attributes.longitude
		}

		const tenantWillChange =
			device.tenantId.toString() !== newTenantId.toString()

		if (tenantWillChange) {
			ready2Patch.move_to_tenant = newTenantId

			const moveDeviceBody = {
				deviceId: device.id,
				tenantId: newTenantId,
				connectChildren: true,
				connectParents: true,
			}

			const moveDeviceRequest = await apiFetch(
				`${instance.api_tenantswitcher}/TenantSwitcher`,
				defaultHeader(this.AccessToken, 'POST', moveDeviceBody)
			)

			if (!moveDeviceRequest.ok) {
				checkToast(t, 15004)
				return false
			}
		}

		const updateAttributesRequest = await apiFetch(
			`${instance.api}/Device/${device.id}`,
			defaultHeader(this.AccessToken, 'PATCH', ready2Patch)
		)
		if (updateAttributesRequest.logout) return

		if (!updateAttributesRequest.ok) {
			checkToast(t, 15003)
			return
		}

		checkToast(t, updateAttributesRequest.data.isUpdated ? 15101 : 15002)

		if (camera) {
			await promisedSetState(this, { makePhoto: true })
		} else {
			// FUTURE: v2.3 - navigate to the right detailspage, when the IOTA Update is done. (Currently stuck in "New installation" after skipping camera)
		}

		this.reloadPage()
	}

	/**
	 * Save user inputs and prepare data for device update.
	 *
	 * @async
	 * @returns {void}
	 */
	saveInputs = async () => {
		const { t, tenant } = this.context
		let allInputs = {}

		const mappedAppControlled = this.state.appControlled.map((input) => {
			return {
				[input.name]: this.state.inputs.find(
					(i) => i.name === input.name
				),
			}
		})

		// TODO: Bug, when changing the lang. and then saving the input

		// Creating object with cleaned values
		mappedAppControlled.forEach((item) => {
			for (const key in item) {
				if (
					item.hasOwnProperty(key) &&
					typeof item[key] !== 'undefined'
				) {
					allInputs[key] =
						item[key].value === '' ? null : item[key].value
				}
			}
		})

		allInputs = validateRequired(t, allInputs, this.required)

		if (!allInputs) return

		allInputs = validateInstallationPlace(t, allInputs, this.props.device)

		if (!allInputs) return

		allInputs = validateInputs(t, allInputs)

		if (!allInputs) return

		const tenantWillChange =
			this.props.device.tenantId.toString() !==
			this.props.newTenantId.toString()

		allInputs.customer_code = tenant.configuration.attributes.customer_code

		this.setState({
			ready2Patch: this.emailSettings(allInputs, tenantWillChange),
			currentModal: 'location',
		})
	}

	componentDidMount = () => {
		this.initLoad(this.props.device)
	}

	// FUTURE: v2.5 - Find a good solution to display all components in the same distance to each other.
	render() {
		const { t, isRole, instance } = this.context
		const {
			changeEditInputs,
			clickDownlink,
			device,
			editInputs,
			navigatePhoto,
			title,
		} = this.props
		const {
			alarmText,
			appControlled,
			currentModal,
			devices,
			inputs,
			loading,
			makePhoto,
		} = this.state

		if (loading) {
			return <LoadingScreen.Spinner className="mt-4" />
		}

		if (makePhoto) {
			return <Navigate to={navigatePhoto + '/camera'} />
		}

		return (
			<div className="px-0 space-y-2 sm:px-5 md:px-10">
				<Headline hr>{title}</Headline>
				<DetailsHeader
					device={device}
					editInputs={editInputs}
					changeEditInputs={changeEditInputs}
					saveInputs={this.saveInputs}
					moveDevice={() => {
						this.setState({ currentModal: 'move' })
					}}
				/>
				<div className="mb-3 space-y-2">
					{alarmText.map((text, i) => (
						<div key={i + '_AlarmRow'}>
							<AlarmRow
								alarmText={text.text}
								color={text.color}
								isUsecase={text.isUsecase}
								device={device}
							/>
						</div>
					))}
				</div>

				<ConnectionBars device={device} />

				<StatusRow device={device} />

				{isRole('edit') && (
					<Toggle
						onChange={() => this.setState({ currentModal: 'prov' })}
						isChecked={device.status === 'enabled'}
					>
						{t('devices.provisioned.toggle')}:
					</Toggle>
				)}

				{isRole('edit') &&
					clickDownlink &&
					instance.downlinks.io.includes(device.typeId) && (
						<Downlinks
							clickDownlink={clickDownlink}
							reloadPage={this.reloadPage}
						/>
					)}

				{isRole('notification') && device.attributes.alarm_emails && (
					<Notification
						editInputs={editInputs}
						device={device}
						changeParent={(mails) => this.setState({ mails })}
					/>
				)}

				{editInputs ? (
					<div className="mt-2">
						<Inputs
							appControlled={appControlled}
							updateParent={this.updateInputs}
							inputs={inputs}
							devices={devices}
							device={device}
							required={this.required}
						/>
					</div>
				) : (
					<Listed
						device={device}
						serial={device.typeId === 1 ? device.edid : null}
						appControlled={appControlled}
					/>
				)}

				<Modals
					currentModal={currentModal}
					closeModal={() => this.setState({ currentModal: null })}
					device={device}
					finalRequest={this.finalRequest}
					reloadPage={this.reloadPage}
					emailSettings={this.emailSettings}
				/>
			</div>
		)
	}
}
