import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import Context from '#context'

import { installationPlace } from '#helper/showData.js'

import IconsDeviceType from '#comp/Custom/IconsDeviceType'
import ConnectionBars from '#comp/ConnectionBars'
import LoadingScreen from '#comp/LoadingScreen'

import { alarmLogic } from '#shared/helper/alarms'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faImage, faEye } from '@fortawesome/free-solid-svg-icons'

import { XyzTransition } from '@animxyz/react'

import AlarmRow from '../AlarmRow'
import StatusRow from '../Details/StatusRow'

/**
 * @class
 * @classdesc - Basicly a the Device Card in the Overview.
 * @example
 * <SingleDevice
 * extended={extended}
 * extendedIds={extendedIds}
 * extendCard={this.extendCard}
 * device={device}
 * setSpecialDevices={this.setSpecialDevices}
 * />
 */
export default class SingleDevice extends Component {
	static contextType = Context

	state = {
		extended: false,
		alarm: 0,
		alarmColor: null,
		alarmText: null,
		language: this.context.language,
	}

	/**
	 * @typedef {Object} PropTypes
	 * @property {Function} extendCard - Closes this card.
	 * @property {Object} device - An object representing a device.
	 * @property {Function} setSpecialDevices - Adds a special device with the given ID and name to the component state.
	 */
	static propTypes = {
		extendCard: PropTypes.func.isRequired,
		device: PropTypes.object.isRequired,
		setSpecialDevices: PropTypes.func.isRequired,
	}
	static defaultProps = {
		extendCard: () => {},
		device: {},
		setSpecialDevices: () => {},
	}

	// TODO: move ifNull to helper.js
	/**
	 * Checks if a value is null or '0' and returns the appropriate result.
	 *
	 * @param {any} value - The value to be checked.
	 * @returns {any} - If the value is '0' or null, returns undefined. Otherwise, returns the original value.
	 */
	ifNull = (value) => {
		if (value === '0' || value === null || value === false) {
			return
		} else {
			return value
		}
	}

	/**
	 * Updates alarm-related state properties based on the provided device information.
	 *
	 * @param {Object} device - The device object for which alarms are to be determined.
	 * @returns {void}
	 */
	alarms = (device) => {
		const myReturn = alarmLogic(this.context.t, device)

		this.setState({
			alarm: myReturn?.alarm,
			alarmColor: myReturn?.alarmColor,
			alarmText: myReturn?.alarmText,
		})

		if (myReturn.setSpecialDevices) {
			this.props.setSpecialDevices(device.id, myReturn.setSpecialDevices)
		}
	}

	componentDidMount = () => {
		const { device } = this.props
		this.alarms(device)
		this.setState({ extended: true })
	}

	componentDidUpdate = () => {
		const { language } = this.context
		if (language !== this.state.language) {
			this.setState({ language })
			this.alarms(this.props.device)
		}
	}

	render() {
		const { t, fetchPicture, pictureLoading } = this.context
		const { extended, alarm, alarmColor, alarmText } = this.state
		const { device, extendCard } = this.props

		return (
			<div className="rounded-md shadow-smAll shadow-gray-300 dark:shadow-gray-700">
				<div
					onClick={() => extendCard(device.id)}
					className="flex items-center justify-between h-16 px-4 text-2xl cursor-pointer sm:px-6"
				>
					<div className="flex items-center justify-center w-6 h-8">
						{/* FUTURE: v2.3 - Mit "DeviceCard"-Componente verschönern gemeinsam (oder vereinen) */}
						{alarmColor && (
							<IconsDeviceType
								type={device.type.split('_')[1]}
								beat={alarm >= 2}
								className={alarmColor}
							/>
						)}
					</div>
					{installationPlace(device.attributes) !== '' && (
						<div className="px-4 text-base truncate md:text-lg">
							{installationPlace(device.attributes)}
						</div>
					)}
					<div className="flex items-center justify-center w-6 h-8">
						<FontAwesomeIcon icon={faAngleUp} />
					</div>
				</div>
				<XyzTransition xyz="small-100% origin-top out-duration-0">
					{extended && (
						<div className="px-2 pb-2 mt-1 space-y-2 text-sm text-center sm:px-6 sm:pb-4 sm:text-base">
							<ConnectionBars device={device} />
							<div className="font-bold">{device.serial}</div>
							<StatusRow device={device} />
							<div>{this.ifNull(device.attributes.comment)}</div>
							<div className="flex justify-between">
								<NavLink to={'device/' + device.id}>
									<div className="flex items-center justify-center h-10 bg-gray-100 border border-gray-800 rounded-md dark:bg-gray-700 dark:border-gray-500 w-28 xxs:w-32">
										<FontAwesomeIcon icon={faEye} />
										<span className="md:mb-0.5 ml-2">
											{t('devices.extended.details')}
										</span>
									</div>
								</NavLink>

								<div
									onClick={() =>
										fetchPicture(device.id, device.serial)
									}
									className="flex items-center justify-center h-10 bg-gray-100 border border-gray-800 rounded-md cursor-pointer dark:bg-gray-700 dark:border-gray-500 w-28 xxs:w-32"
								>
									{pictureLoading ? (
										<LoadingScreen.Spinner size="md" />
									) : (
										<>
											<FontAwesomeIcon icon={faImage} />
											<span className="md:mb-0.5 ml-2">
												{t('devices.extended.picture')}
											</span>
										</>
									)}
								</div>
							</div>
							{alarmText.map((text, i) => (
								<div key={i + '_AlarmRow'}>
									<AlarmRow
										alarmText={text.text}
										color={text.color}
										isUsecase={text.isUsecase}
										device={device}
									/>
								</div>
							))}
						</div>
					)}
				</XyzTransition>
			</div>
		)
	}
}
