import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Context from '#context'
import checkToast from '#toast'

import { onChange } from '#helper/onChange'

import Select from '#comp/Custom/Select'

// TODO: Select rename so there is no conflict with the custom component. (DeviceSelect)

/**
 * @class
 * @classdesc - Display a Select component inside editable devices.
 * @example
 * <Select input={input} inputs={inputs} />
 */
export default class Inputs extends Component {
	static contextType = Context

	state = { noOption: false }

	/**
	 * @typedef {Object} PropTypes
	 * @property {Object} input - The required input object.
	 * @property {Array} inputs - The required array of inputs.
	 */
	static propTypes = {
		input: PropTypes.object.isRequired,
		inputs: PropTypes.array.isRequired,
	}
	static defaultProps = {
		input: {},
		inputs: [],
	}

	/**
	 * Finds a specific catalog entry by name and extracts its values.
	 *
	 * @param {Array} cata - The catalog array to search through.
	 * @param {String} name - The name of the catalog entry to find.
	 * @returns {Array} - An array containing the values of the found catalog entry, can also be empty.
	 */
	findCatalogue = (cata, name) => {
		let myReturn = {}
		cata.find((c) => c.name === name).values.forEach((t) => {
			myReturn[t.name] = t.code
		})
		return Object.values(myReturn)
	}

	/**
	 * The whole logic to create options for the Select component.
	 *
	 * @returns {void}
	 */
	installationPlaceCheck = () => {
		const maxInstallationPlaces = 9

		var currentInstallationPlace = this.props.inputs.find(
			(input) => input.name === 'installation_place'
		).value

		if (currentInstallationPlace === null) {
			currentInstallationPlace = this.findCatalogue(
				this.context.catalogue,
				'spar_installation_place'
			)[0]
		}

		const selectedInstallationPlaceData = {
			name: currentInstallationPlace,
			values: Array.from({ length: maxInstallationPlaces }, (_, i) =>
				(i + 1).toString()
			),
		}

		if (selectedInstallationPlaceData) {
			const currentName = selectedInstallationPlaceData.name
			const allDevices = this.context.devices
				.map((device) => {
					var iPlace = device.attributes?.installation_place
					var iNumber = device.attributes?.installation_number
					if (
						iPlace !== null &&
						iNumber !== null &&
						iPlace !== '0' &&
						iNumber !== '0' &&
						device.id !== this.context.device.id
					) {
						return { place: iPlace, number: iNumber }
					}
					return null
				})
				.filter((device) => device !== null)
			const allValues = selectedInstallationPlaceData.values.map(
				(value) => {
					var myFind = allDevices
						.filter((device) => currentName === device.place)
						.map((device) => device.number)
					return { value, disabled: myFind.includes(value) }
				}
			)

			return allValues
		} else {
			checkToast(this.context.t, 13011)
			return []
		}
	}

	/**
	 * Loads the last available combination based on certain conditions.
	 *
	 * @returns {void}
	 */
	loadLastAvailableCombination = () => {
		if (
			this.props.inputs.find(
				(input) => input.name === 'installation_number'
			) &&
			this.props.inputs.find(
				(input) => input.name === 'installation_place'
			).value !== 'Sonstiges'
		) {
			var disabledNumber = this.installationPlaceCheck().filter(
				(i) => !i.disabled
			)

			this.setState({ noOption: disabledNumber.length === 0 })
			disabledNumber.push({ value: 'noOption' })
			this.context.updateParent({
				installation_number: disabledNumber[0].value,
			})
		}
	}

	componentDidMount() {
		this.loadLastAvailableCombination()
	}

	componentDidUpdate(prevProps) {
		const nameInstallation = (input) => input.name === 'installation_place'
		if (
			prevProps.inputs.find(nameInstallation) !==
			this.props.inputs.find(nameInstallation)
		) {
			this.loadLastAvailableCombination()
		}
	}

	render() {
		const { updateParent, catalogue, t } = this.context
		const { noOption } = this.state
		const { input } = this.props

		return (
			<Select
				className="w-full"
				name={input.name}
				defaultValue={
					input.value === null
						? this.findCatalogue(catalogue, input.catalogue)[0]
						: input.value
				}
				onChange={(e) => {
					onChange(e, (keyValue) => updateParent(keyValue))
				}}
				label={input.displayname}
			>
				{input.name === 'installation_number'
					? this.installationPlaceCheck().map((option, i) => (
							<option
								key={i}
								value={option.value}
								name={option.value}
								disabled={option.disabled}
							>
								{option.value}
							</option>
					  ))
					: this.findCatalogue(catalogue, input.catalogue).map(
							(option, i) => (
								<option key={i} value={option} name={option}>
									{option}
								</option>
							)
					  )}
				{noOption && (
					<option key="noOption" value="noOption" name="noOption">
						{t('devices.noInstallationNumber')}
					</option>
				)}
			</Select>
		)
	}
}
