import React, { Component } from 'react'
import { focusColor } from '#helper/colors'

// DOKU:

export default class OutlinedBox extends Component {
	render() {
		const { className, children, error, success, color, name, label } =
			this.props

		return (
			<div className={'relative' + (className ? ' ' + className : '')}>
				<div
					name={name}
					className={
						'!ring-0 h-full w-full rounded-lg bg-transparent border-2 px-3 py-3 font-sans text-sm font-normal outline outline-0 placeholder-shown:border border-t-transparent dark:border-t-transparent ' +
						focusColor(error, success, color).border
					}
				>
					{children}
				</div>
				<label
					className={
						`before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-sm font-normal leading-tight before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-lg before:border-t-2 before:border-l-2 after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-lg after:border-t-2 after:border-r-2 ` +
						focusColor(error, success, color).label
					}
				>
					{label}
				</label>
			</div>
		)
	}
}
