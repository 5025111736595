import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { getLS } from '#helper/localStorage'
import { changeSelected } from '#helper/camera'

import { Selector, QRScanner } from '#comp/Webcam'

/**
 * @class
 * @classdesc - Scanner with a selector
 * @example
 * <Scanner setCode={this.setCode} />
 */
export default class Scanner extends Component {
	state = {
		selectedDeviceId: getLS('selectedCamera') || 'undefined',
		startCam: false,
		torchActive: false,
	}

	/**
	 * @typedef {Object} PropTypes
	 * @property {Function} setCode - A function to set the code.
	 */
	static propTypes = {
		setCode: PropTypes.func.isRequired,
	}
	static defaultProps = {
		setCode: () => {},
	}

	/**
	 * Extracts and returns the DEVEUI (Device EUI) from a scanned text based on specific patterns.
	 *
	 * @param {String} text - The scanned text.
	 * @returns {String} - The extracted DEVEUI.
	 */
	extractScannedCode = (text) => {
		let DEVEUI

		if (text.startsWith('LW:D0:')) {
			// IO Box Engico
			DEVEUI = text.split(':')[3]
		} else if (text.startsWith('HTTPS://WWW.MILESIGHT-IOT.COM/')) {
			// All Milesights
			const url = new URL(text)
			const urlParams = new URLSearchParams(url.search)
			DEVEUI = urlParams.get('SN')
		} else {
			DEVEUI = text
		}

		return DEVEUI
	}

	/**
	 * Handles the result of a barcode or QR code scan, updates the component state, and triggers a callback to set the code.
	 *
	 * @param {Object} result - The result object from the scanning operation.
	 * @returns {void}
	 */
	resultScanner = (result) => {
		this.setState({ startCam: false }, () =>
			this.props.setCode(this.extractScannedCode(result.text))
		)
	}

	render() {
		const { selectedDeviceId, startCam } = this.state

		return (
			<div>
				<Selector
					selectedDeviceId={selectedDeviceId}
					startScanner={() => this.setState({ startCam: !startCam })}
					changeSelected={(id) => this.setState(changeSelected(id))}
					startCam={startCam}
				/>
				{startCam && (
					<QRScanner
						resultScanner={this.resultScanner}
						deviceid={selectedDeviceId}
					/>
				)}
			</div>
		)
	}
}
