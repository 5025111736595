import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import Context from '#context'
import checkToast from '#toast'

import { getLS, removeLS, saveLS } from '#helper/localStorage'
import apiFetch, { defaultHeader } from '#helper/Fetch API/request'

import Wrap from '#comp/Wrapper/Wrap'
import Breadcrumb from '#comp/Breadcrumb'
import LoadingScreen from '#comp/LoadingScreen'

import instances from '#shared/backend/instances'
import defaultValues from '#shared/backend/defaultValues'

import TenantRouter from './Tenant/Router'
import Login from './Login'
import Header from '../Structure/Header'

/**
 * @class
 * @classdesc - InstanceRouter component that handles authentication and user profile for a specific instance.
 * @example
 * <Wrap routeEl={InstanceRouter} />
 */
export default class InstanceRouter extends Component {
	static contextType = Context

	state = {
		auth: null,
		loading: true,
		profile: null,
		currentBreadcrumb: {},
		temperatureThresholds: [],
	}

	/**
	 * @typedef {Object} ParamsShape
	 * @property {String} [api] - The api parameter of the url.
	 * @property {...*} [otherProps] - Additional properties that may be present in the params.
	 * @typedef {Object} PropTypes
	 * @property {ParamsShape} params
	 * @property {Object} [locations]
	 * @property {ReactNode} routeEl
	 */
	static propTypes = {
		params: PropTypes.shape({
			api: PropTypes.string.isRequired,
		}),
		locations: PropTypes.object,
		routeEl: PropTypes.func.isRequired,
	}
	static defaultProps = {
		params: { api: '' },
	}

	shortLink = this.props.params.api

	/**
	 * Finds the instance object with the same shortLink as the current component's shortLink property.
	 *
	 * @returns {Object|null} - The instance object with the matching shortLink or null if not found.
	 */
	myInstance = () => {
		return instances.find((el) => el.shortLink === this.shortLink) || null
	}

	instanceApi = this.myInstance()?.api
	authLink = 'auth_' + this.myInstance()?.shortLink

	/**
	 * Logs out the user by removing their authentication from local storage and resetting the component's state.
	 *
	 * @returns {void}
	 */
	logout = () => {
		const { sidebar, changeSidebar, t } = this.context
		removeLS(this.authLink)
		if (sidebar) {
			changeSidebar()
		}
		checkToast(t, 11102)
		this.setState({
			auth: null,
			profile: null,
			loading: false,
		})
	}

	/**
	 * Makes an API fetch using the specified URL and options.
	 *
	 * @async
	 * @param {String} url - The URL for the API fetch.
	 * @param {Object|String} options - The options for the API fetch. Can be an object or a string.
	 * @returns {Promise<any>} - A Promise that resolves to the API response.
	 */
	apiFetch = async (url, options) => {
		if (typeof options === 'string') {
			options = defaultHeader(options)
		}
		const myResponse = await apiFetch(url, options, this.logout)
		return myResponse
	}

	/**
	 * Gets all temperature thresholds from Fileserver
	 *
	 * @returns {Array} - All temperature thresholds fetched from Fileserver
	 */
	getTemperatureThresholds = async () => {
		const temperatureThresholdsRequest = await this.apiFetch(
			'https://sens-fileserver.xaas-a0a0.cloud/sens/SENS-APP/temperatureThresholds.json'
		)
		if (temperatureThresholdsRequest.logout) return null

		if (!temperatureThresholdsRequest.ok) {
			checkToast(this.context.t, 14002)
			return null
		}
		return temperatureThresholdsRequest.data
	}

	/**
	 * Retrieves the user profile information from the API.
	 *
	 * @async
	 * @param {Object} auth - The authentication object.
	 * @returns {void}
	 */
	getProfile = async (auth) => {
		const profileRequest = await this.apiFetch(
			`${this.instanceApi}/Users/Profile`,
			auth.access_token
		)
		if (profileRequest.logout) return

		if (profileRequest.ok) {
			const temperatureThresholds = await this.getTemperatureThresholds()

			// Set the component state with the retrieved data.
			this.setState({
				auth: auth,
				loading: false,
				profile: profileRequest.data,
				temperatureThresholds,
			})
		} else {
			this.logout()
			checkToast(this.context.t, 14001)
		}
	}

	/**
	 * Logs in the user with the provided username and password.
	 *
	 * @async
	 * @param {String} username - The username of the user.
	 * @param {String} password - The password of the user.
	 * @returns {void}
	 */
	login = async (username, password) => {
		const { t } = this.context
		if (username === '' || password === '') {
			checkToast(t, 11003)
			return
		}
		const loginRequest = await this.apiFetch(
			`${this.instanceApi}/Authentication?username=${username}&password=${password}`,
			{ method: 'POST' }
		)
		if (loginRequest.ok) {
			saveLS(this.authLink, loginRequest.data)
			this.getProfile(loginRequest.data)
		} else if (loginRequest.data.error === 'invalid_credentials') {
			checkToast(t, 11002)
		} else if (!loginRequest.status) {
			checkToast(t, 10005)
		} else {
			checkToast(t, 11005)
		}
	}

	/**
	 * Checks if the current user has a specific role or is admin.
	 *
	 * @param {String} role
	 * @returns {Boolean} - Whether current has the role or is admin.
	 */
	isRole = (role) => {
		const { profile } = this.state

		if (profile) {
			const roles = profile.roles
			const path = defaultValues.rolesAccess[role]
			const admin = defaultValues.rolesAccess.admin

			if (roles.includes(admin) || roles.includes(path)) {
				return true
			}
		}

		return false
	}

	/**
	 * Checks if the provided authentication is valid.
	 *
	 * @param {Object} auth - The user authentication object.
	 * @returns {Boolean} - Whether the authentication is valid or not.
	 */
	checkValidAuth = (auth) => {
		var nowUnix = Math.round(new Date().getTime() / 1000)
		if (!auth || nowUnix > auth.expiration_time) {
			return false
		}
		return true
	}

	/**
	 * Sets the current breadcrumb state of the component.
	 *
	 * @param {String} key - The key of the breadcrumb.
	 * @param {String} value - The value of the breadcrumb.
	 * @returns {void}
	 */
	setBreadcrumb = (key, value) => {
		if (typeof key === 'undefined' && typeof value === 'undefined') {
			this.setState({ currentBreadcrumb: {} })
		} else {
			this.setState({
				currentBreadcrumb: {
					...this.state.currentBreadcrumb,
					[key]: value,
				},
			})
		}
	}

	// Checks if the user has a valid authentication and gets their profile if so.
	componentDidMount = () => {
		var myAuth = JSON.parse(getLS(this.authLink))
		if (
			myAuth &&
			myAuth.access_token &&
			this.checkValidAuth(myAuth.access_token)
		) {
			this.getProfile(myAuth)
		} else {
			this.setState({ loading: false })
		}
	}

	render() {
		const {
			loading,
			auth,
			profile,
			currentBreadcrumb,
			temperatureThresholds,
		} = this.state

		if (!this.myInstance()) {
			return <Navigate to="/" replace />
		}

		if (loading) {
			return <LoadingScreen.Spinner fullScreen />
		}

		return (
			<Context.Provider
				value={{
					...this.context,
					auth: auth,
					profile: profile,
					isRole: this.isRole,
					login: this.login,
					logout: this.logout,
					instance: this.myInstance(),
					setBreadcrumb: this.setBreadcrumb,
					currentBreadcrumb: currentBreadcrumb,
					apiFetch: this.apiFetch,
					temperatureThresholds,
				}}
			>
				<div className="relative h-full">
					<div className="sticky top-0 z-40 w-full">
						<Header />
						<Wrap routeEl={Breadcrumb} />
					</div>

					<div className="p-4">
						<Routes>
							{auth && (
								<>
									<Route
										path="/"
										element={
											<Navigate to="tenant" replace />
										}
									/>
									<Route
										path="tenant/*"
										element={<TenantRouter />}
									/>
								</>
							)}
							<Route path="/" element={<Login />} />

							<Route
								path="*"
								element={<Navigate to="./" replace />}
							/>
						</Routes>
					</div>
				</div>
			</Context.Provider>
		)
	}
}
